import { useState, useEffect, useCallback, useContext } from "react";

import { MdOutlineNorthEast } from "react-icons/md";

import { BasicLoader, CommonError, SectionHeader, ZeroResults, fetchHeaders } from "../Utils/Common";
import { API_BASE_URL } from "../../Constants/Contants";

export const Ebay = ({ q }) => {
  let [isLoaded, setIsLoaded] = useState(false);
  let [content, setContent] = useState(false);

  const getData = useCallback(() => {
    fetch(API_BASE_URL + "/analyze/ebay?q=" + q, fetchHeaders())
      .then((response) => response.json())
      .then((data) => {
        setContent(data.result);
        setIsLoaded(true);
        console.log("eBay Fetched");
      });
  }, [q]);

  useEffect(() => {
    getData();
  }, [q, getData]);

  function displayProducts() {
    let listItems;

    // Check if there is no error
    if (content.status_code !== 200) {
      if (content.status_code === 600) {
        listItems = <ZeroResults />;
      } else {
        listItems = <CommonError message={content.status_message} />;
      }
    } else {
      listItems = content.response.searchResult.item.map((x, index) => {
        if (index < content.limit) {
          return <Product key={x.itemId} data={x} />;
        }

        return null;
      });
    }

    return (
      <div id="ebay">
        <SectionHeader title="Ebay Related Products" ToolTipTitle="Ebay Products" ToolTipPointer="ebay_shopping" />

        <div className="eb-header">
          <div className="eb-1">Image</div>
          <div className="eb-2">Title</div>
          <div className="eb-3">Price</div>
          <div className="eb-4">Category</div>
          <div className="eb-5">Condition</div>
        </div>
        {listItems}
      </div>
    );
  }

  function Product({ data }) {
    let style = {
      backgroundImage: `url(${data.galleryURL})`,
      backgroundSize: "cover",
    };

    return (
      <div className="eb-entry">
        <div className="eb-1" style={style}></div>
        <div className="eb-2">
          <a href={data.viewItemURL} target="_blank" rel="noreferrer">
            {data.title.substring(0, 200)} ... <MdOutlineNorthEast className="ext-link" />
          </a>
        </div>
        <div className="eb-3">${Number(data.sellingStatus.convertedCurrentPrice).toFixed(2)}</div>
        <div className="eb-4">{data.primaryCategory.categoryName}</div>
        <div className="eb-5">{data.condition ? data.condition.conditionDisplayName : "-"}</div>
      </div>
    );
  }

  if (isLoaded) {
    return <>{displayProducts()}</>;
  } else {
    return (
      <>
        <SectionHeader title="Ebay Related Products" ToolTipTitle="Ebay Products" ToolTipPointer="ebay_shopping" />
        <BasicLoader />
      </>
    );
  }
};
