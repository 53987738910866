export const PageTitle = ({ title }) => {
    let pTitlte = title ? title : "Page Title";

    return (
        <div id='page-header' className='text-center mb-12 mt-6'>
            <h1 className='text-xl tracking-widest uppercase inline-block mx-auto px-20 py-4 bg-slate-600 text-white rounded-full'>
                {pTitlte}
            </h1>
        </div>
    );
};
