import { useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { AppContext } from "../../Contexts/AppContexts";
import { ToolTipHelper } from "../Utils/Common";

const ModeSelector = () => {
  const { mode, setMode, setDoSearch } = useContext(AppContext);
  const modeSelectorContainer = useRef("");
  const navigate = useNavigate();

  const getClasses = (sMode) => {
    const commonCss = "text-xs uppercase  tracking-wider";
    if (mode === sMode) {
      return "active " + commonCss + " text-white bg-orange-600";
    } else {
      return commonCss + " text-black hover:text-orange-600 cursor-pointer";
    }
  };

  return (
    <div ref={modeSelectorContainer} className="ms">
      <div className={getClasses("expand")}>
        <div
          className="inline"
          onClick={() => {
            setMode("expand");
            setDoSearch(false);
            navigate("/");
          }}
        >
          Keywords
        </div>
        <ToolTipHelper title="Generate Keywords" pointer="generate_keywords" />
      </div>

      <div className={getClasses("ask")}>
        <div
          className="inline"
          onClick={() => {
            setMode("ask");
            setDoSearch(false);
            navigate("/");
          }}
        >
          Questions
        </div>
        <ToolTipHelper
          title="Generate Questions"
          pointer="generate_questions"
        />
      </div>

      <div className={getClasses("analyze")}>
        <div
          className="inline"
          onClick={() => {
            setMode("analyze");
            setDoSearch(false);
            navigate("/");
          }}
        >
          Analysis
        </div>
        <ToolTipHelper title="Analyze Keyword" pointer="analyze_keywords" />
      </div>

      {/* <div className={getClasses("ama")}>
        <div
          className="inline"
          onClick={() => {
            setMode("ama");
            setDoSearch(false);
            navigate("/");
          }}
        >
          Ask Me Anything
        </div>
        <ToolTipHelper title="Ask Me Anything" pointer="ask_me_anything" />
      </div> */}

      <div className={getClasses("discover")}>
        <div
          className="inline"
          onClick={() => {
            setMode("discover");
            setDoSearch(false);
            navigate("/");
          }}
        >
          Discover
        </div>
        <ToolTipHelper title="Search Directory" pointer="keyword_directory" />
      </div>
    </div>
  );
};

export default ModeSelector;
