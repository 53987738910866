import { SectionHeader } from "../Utils/Common";
import { Products } from "./Merchant/Products";

export const Merchant = ({ q }) => {
  function Amazon() {
    return (
      <>
        <SectionHeader
          title={`Amazon Shopping Related Products`}
          ToolTipTitle={`Amazon Shopping`}
          ToolTipPointer={"amazon_shopping"}
        />
        <Products
          key={"an-amazon-p" + q.replace(/\s/g, "")}
          q={q}
          merchant="amazon"
        />
      </>
    );
  }

  function GoogleShopping() {
    return (
      <>
        <SectionHeader
          title={`Google Shopping Related Products`}
          ToolTipTitle={`Google Shopping`}
          ToolTipPointer={"google_shopping"}
        />
        <Products
          key={"an-google-p" + q.replace(/\s/g, "")}
          q={q}
          merchant="google"
        />
      </>
    );
  }

  return (
    <>
      <Amazon key={"an-amazon-" + q.replace(/\s/g, "")} />
      <GoogleShopping key={"an-googleshop-" + q.replace(/\s/g, "")} />
    </>
  );
};
