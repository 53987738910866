import { useContext, useEffect, useCallback } from "react";

import { AppContext } from "../../Contexts/AppContexts";
import { DiscoverContext } from "../../Contexts/DiscoverContexts";

import { BasicLoader, fetchHeaders } from "../Utils/Common";
import { API_BASE_URL } from "../../Constants/Contants";

import NicheMainCategory from "./NicheMainCategory";
import NicheSubCategory from "./SubCategory";
import NichesList from "./NichesList";

const Discover = () => {
    const { mode, doSearch } = useContext(AppContext);
    const {
        displayMode,
        subCategoryID,
        nichesCategoryId,
        mainCategoryData,
        subCategoryData,
        isLoaded,
        setMainCategoryData,
        setSubCategoryData,
        setNichesCategoryData,
        setIsLoaded,
        setDisplayMode,
    } = useContext(DiscoverContext);

    useEffect(() => {
        if (!doSearch) {
            if (displayMode === "main-category") {
                if (mainCategoryData) {
                    setIsLoaded(true);
                } else {
                    fetchMainCatagory();
                }
            }

            if (displayMode === "sub-category") {
                if (subCategoryData) {
                    setIsLoaded(true);
                } else {
                    fetchSubCatagory();
                }
            }

            if (displayMode === "niches") {
                console.log("fetching niches");
                fetch(
                    API_BASE_URL +
                        "/niche-from-category?id=" +
                        nichesCategoryId,
                    fetchHeaders()
                )
                    .then((response) => response.json())
                    .then((data) => {
                        setNichesCategoryData(data);
                        setIsLoaded(true);
                    });
            }
        } else {
            setDisplayMode("search");
            setIsLoaded(false);
        }
    }, [displayMode, mainCategoryData, subCategoryData, doSearch]);

    const fetchMainCatagory = useCallback(() => {
        // console.log('fetching main')
        fetch(API_BASE_URL + "/info", fetchHeaders())
            .then((response) => response.json())
            .then((data) => {
                setMainCategoryData(data);
            });
    }, []);

    const fetchSubCatagory = useCallback(() => {
        // console.log('fetching sub from ' + subCategoryID)
        fetch(
            API_BASE_URL + "/sub-category?id=" + subCategoryID,
            fetchHeaders()
        )
            .then((response) => response.json())
            .then((data) => {
                setSubCategoryData(data);
            });
    }, [subCategoryID]);

    function printResults() {
        if (displayMode === "main-category") {
            if (isLoaded) {
                // console.log('Displaying Main Category')
                return <NicheMainCategory />;
            } else {
                return (
                    <>
                        <BasicLoader />
                    </>
                );
            }
        }

        if (displayMode === "sub-category") {
            if (isLoaded) {
                // console.log('Displaying Sub Category')
                return <NicheSubCategory />;
            } else {
                return (
                    <>
                        <BasicLoader />
                    </>
                );
            }
        }

        if (displayMode === "niches") {
            if (isLoaded) {
                // console.log('Displaying Niches From Sub Category')
                return <NichesList />;
            } else {
                return (
                    <>
                        <BasicLoader />
                    </>
                );
            }
        }

        if (displayMode === "search") {
            if (isLoaded) {
                // console.log('Displaying Niches From Sub Category')
                return <>ABC</>;
            } else {
                return (
                    <>
                        <BasicLoader />
                    </>
                );
            }
        }
    }

    // The main return
    return (
        <div
            id='discover'
            className={mode === "discover" ? "block mb-8" : "hidden"}
        >
            {printResults()}
        </div>
    );
};

export default Discover;
