import { useContext } from "react";

import { AppContext } from "../../Contexts/AppContexts";
import { BasicPrompt } from "../Utils/Common";
import { AiGetCompletion } from "./AiGetCompletion";

const AskMeAnything = () => {
  const { queryString, doSearch } = useContext(AppContext);

  if (queryString && doSearch) {
    return (
      <>
        <div id="ama-wrap">
          <div id="ama">
            <h1 className="result-header">{queryString}</h1>
            <AiGetCompletion />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div id="ama">
        <BasicPrompt promt="Please enter a anything that you what to know ..." />
      </div>
    );
  }
};

export default AskMeAnything;
