import "./App.css";

import { useState } from "react";
import { AppContext } from "./Contexts/AppContexts";
import { AuthContext } from "./Contexts/AuthContexts";

import { Routes, Route } from "react-router-dom";
import ProtectedRoutes from "./Components/Pages/Authenticator";

import Home from "./Components/Pages/Home";
import Account from "./Components/Pages/Account";
import Login from "./Components/Pages/Login";
import Logout from "./Components/Pages/Logout";
import Support from "./Components/Pages/Support";
import Training from "./Components/Pages/Training";
import Validation from "./Components/Pages/Validation";
import Plan from "./Components/Pages/Plan";

function App() {
  let userInfo = localStorage.getItem("user_info");
  let validInfo = true;

  // Let's make sure we have valid info before proceeding
  try {
    userInfo = JSON.parse(userInfo);
  } catch (e) {
    validInfo = false;
    userInfo = {};
  }

  let [mode, setMode] = useState("expand");
  let [country, setCountry] = useState({
    country_code: "US",
    location_code: "2840",
    country_name: "United States",
  });

  let [amaOptions, setAmaOptions] = useState({
    model: "gpt-3.5-turbo",
    system_content: "",
    max_tokens: 30,
    temperature: 1,
    top_p: 1,
    n: 1,
    stop: null,
  });

  let [expandMode, setExpandMode] = useState("related");
  let [accountInfo, setAccountInfo] = useState(userInfo);

  let [queryString, setQueryString] = useState("");
  let [doSearch, setDoSearch] = useState(false);

  let initAuth = false;

  if (localStorage.getItem("token")) {
    initAuth = true;
  }

  let [userAuth, setUserAuth] = useState(initAuth);

  // We have corrupted information, let's do some cleanup
  if (!validInfo) {
    return <Logout />;
  }

  console.log("App re-renders");
  return (
    <AppContext.Provider
      value={{
        mode,
        country,
        expandMode,
        queryString,
        doSearch,
        accountInfo,
        amaOptions,
        setExpandMode,
        setMode,
        setCountry,
        setQueryString,
        setDoSearch,
        setAccountInfo,
        setAmaOptions,
      }}
    >
      <AuthContext.Provider value={{ userAuth, setUserAuth }}>
        <Routes>
          <Route element={<ProtectedRoutes auth={userAuth} />}>
            <Route path="/" element={<Home />}></Route>
            <Route path="plan" element={<Plan />}></Route>

            <Route path="account" element={<Account />}></Route>

            <Route path="training" element={<Training />}></Route>

            <Route path="support" element={<Support />}></Route>

            <Route path="validation" element={<Validation />}></Route>
          </Route>

          <Route path="contact" element={<Support />}></Route>

          <Route path="login" element={<Login />}></Route>
          <Route path="logout" element={<Logout />}></Route>
        </Routes>
      </AuthContext.Provider>
    </AppContext.Provider>
  );
}

export default App;
