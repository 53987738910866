import { useState, useEffect, useCallback, useContext } from "react";
import { MdCircle, MdOutlineNorthEast } from "react-icons/md";

import { BasicLoader, CommonError, ZeroResults, fetchHeaders, SectionHeader } from "../Utils/Common";
import { API_BASE_URL } from "../../Constants/Contants";

export const GoDaddy = ({ q }) => {
  let [isLoaded, setIsLoaded] = useState(false);
  let [content, setContent] = useState(false);

  const getGD = useCallback(() => {
    fetch(API_BASE_URL + "/analyze/gd?q=" + q, fetchHeaders())
      .then((response) => response.json())
      .then((data) => {
        setContent(data);
        setIsLoaded(true);
        console.log("GoDaddy Fetched");
      });
  }, [q]);

  useEffect(() => {
    getGD();
  }, [q, getGD]);

  function displayDomains() {
    let listItems;

    // Check if there is no error
    if (content.result.status_code !== 200) {
      if (content.result.status_code === 600) {
        listItems = <ZeroResults />;
      } else {
        listItems = <CommonError message={content.result.status_message} />;
      }
    } else {
      listItems = content.result.response.map((x, index) => {
        return <Domain key={index} info={x} />;
      });
    }

    return (
      <div id="godaddy">
        <SectionHeader title="Available Domains" ToolTipTitle="Available Domains" ToolTipPointer="godaddy_domains" />

        <div className="gd-entries">{listItems}</div>
      </div>
    );
  }

  const Domain = ({ info }) => {
    return (
      <div className="gd-entry">
        <div className="gd-a">
          <a
            href={"https://godaddy.com/domainsearch/find?checkAvail=1&domainToCheck=" + info.domain}
            target="_blank"
            rel="noreferrer"
          >
            <MdCircle className="gd-a-a" /> {info.domain} <MdOutlineNorthEast className="ext-link" />
          </a>
        </div>
      </div>
    );
  };

  if (isLoaded) {
    return <>{displayDomains()}</>;
  } else {
    return (
      <>
        <SectionHeader title="Available Domains" ToolTipTitle="Available Domains" ToolTipPointer="godaddy_domains" />
        <BasicLoader />
      </>
    );
  }
};
