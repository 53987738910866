import { useState, useEffect, useContext } from "react";
import { API_BASE_URL } from "../../Constants/Contants";
import { BasicLoader, fetchHeaders } from "../Utils/Common";
import { PageTitle } from "./PageTitle";

import SavedNiche from "./Account/SavedNiche";
import SearchHistory from "./Account/SearchHistory";
import UserProfile from "./Account/UserProfile";
import PdfBranding from "./Account/PdfBranding";
import { AppContext } from "../../Contexts/AppContexts";

function Plan() {
  let [isLoaded, setIsLoaded] = useState(false);
  let [content, setContent] = useState("");

  const { setAccountInfo } = useContext(AppContext);

  function getUser(params) {
    fetch(API_BASE_URL + "/user", fetchHeaders())
      .then((response) => response.json())
      .then((data) => {
        localStorage.setItem("user_info", JSON.stringify(data.result));

        setAccountInfo(data.result);
        setContent(data.result);

        setIsLoaded(true);
      });
  }

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {}, [content]);

  if (isLoaded && content) {
    return <PageTitle title="Plan" />;
  } else {
    return <BasicLoader />;
  }
}

export default Plan;
