import { useState, useEffect, useContext } from "react";

import {
  ResponsiveContainer,
  AreaChart,
  XAxis,
  YAxis,
  Area,
  Tooltip,
  CartesianGrid,
  ReferenceLine,
} from "recharts";

import WordCloud from "react-d3-cloud";

import { API_BASE_URL, MONTHS } from "../../Constants/Contants";
import { difficultyColor, ToolTipHelper } from "../Utils/Common";
import { AppContext } from "../../Contexts/AppContexts";

import { BasicLoader, fetchHeaders } from "../Utils/Common";
import { KeywordDifficulty } from "../Analyze/Keyword-Difficulty";

export function ExpandChart({ data, sourceContent }) {
  let [isLoaded, setIsLoaded] = useState(false);
  let [content, setContent] = useState(data);

  const { queryString, country } = useContext(AppContext);

  useEffect(() => {
    // If data fed is missing, then we need to call another api
    if (!data) {
      getKeywordData(queryString);
      setIsLoaded(false);
    } else {
      setContent(data);
      setIsLoaded(true);
    }
  }, [data]);

  const getKeywordData = (keyword) => {
    fetch(
      API_BASE_URL +
        "/analyze/historical-search-volume?q=" +
        keyword +
        "&location_code=" +
        country.location_code,
      fetchHeaders()
    )
      .then((response) => response.json())
      .then((data) => {
        // Since this is coming from a different api, we need to restructure it to fit.
        if (data.result.status_code === 200) {
          let d = {
            sv: data.result.response[0].keyword_info.search_volume,
            cpc: data.result.response[0].keyword_info.cpc,
            difficulty:
              data.result.response[0].keyword_properties.keyword_difficulty,
            dataSet:
              data.result.response[0].keyword_info.monthly_searches.slice(
                0,
                12
              ),
          };

          setContent(d);
          setIsLoaded(true);
        }
      });
  };

  const averageValue = () => {
    let val = "";
    content.dataSet.map((item) => {
      val = Number(val + item.search_volume);
      return null;
    });

    return parseInt(Number(val / content.dataSet.length));
  };

  if (isLoaded) {
    return (
      <>
        <div className="kd">
          <div className="kd-1">
            <h4 className="uppercase font-bold text-slate-400">
              Search Volume
              <ToolTipHelper
                title="Search Volume"
                pointer="organic_search_volume"
              />
            </h4>

            <div className="kd-1-a">{Number(content.sv).toLocaleString()}</div>
          </div>

          <div className="kd-2">
            <h4 className="uppercase font-bold text-slate-400">
              Cost Per Click
              <ToolTipHelper title="Cost Per Click" pointer="cost_per_click" />
            </h4>
            <div className="kd-2-a">{Number(content.cpc).toFixed(2)}</div>
          </div>

          <div className="kd-3">
            <h4 className="uppercase font-bold text-slate-400">
              Keyword Difficulty
              <ToolTipHelper
                title="Cost Per Click"
                pointer="keyword_difficulty"
              />
            </h4>
            <div className="kd-3-a">
              {content.difficulty === "--" ? (
                <KeywordDifficulty
                  keyword={content.keyword}
                  updateList={true}
                />
              ) : (
                <div
                  className={
                    difficultyColor(content.difficulty) + " difficulty"
                  }
                >
                  {content.difficulty}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="g-wrap">
          <div className="g-a">
            <div className="g-a-1">
              <h4 className="uppercase font-bold text-slate-400">
                12 Month Search Trend
                <ToolTipHelper
                  title="Search Trend"
                  pointer="12_month_search_trend"
                />
              </h4>

              <div className="g-a-1">
                <ResponsiveContainer>
                  <AreaChart data={content.dataSet.slice(0).reverse()}>
                    <defs>
                      <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
                        <stop
                          offset="0%"
                          stopColor="#EA580C"
                          stopOpacity={0.4}
                        />
                        <stop
                          offset="75%"
                          stopColor="#EA580C"
                          stopOpacity={0}
                        />
                      </linearGradient>
                    </defs>

                    <Area
                      dataKey="search_volume"
                      stroke="#EA580C"
                      fill="url(#color)"
                      type="monotone"
                    />

                    <CartesianGrid
                      strokeDasharray="1 1"
                      opacity={0.1}
                      vertical={false}
                    />

                    <Tooltip content={<CustomTooltip />} />

                    <XAxis
                      dataKey="month"
                      tickLine={false}
                      axisLine={false}
                      tick={{ fontSize: 10 }}
                      tickFormatter={(month) => MONTHS[month - 1]}
                    />
                    <YAxis
                      dataKey="search_volume"
                      tickLine={false}
                      axisLine={false}
                      type={"number"}
                      tickFormatter={(number) =>
                        Number(number).toLocaleString(2)
                      }
                      tick={{ fontSize: 10 }}
                    />
                    <ReferenceLine
                      y={averageValue()}
                      stroke="green"
                      strokeDasharray="3 3"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
          <div className="g-b">
            <h4 className="uppercase font-bold text-slate-400">
              Keyword Cloud
              <ToolTipHelper title="Search Volume" pointer="keyword_cloud" />
            </h4>

            {sourceContent.result.word_cloud ? (
              <WordCloud
                data={sourceContent.result.word_cloud}
                rotate={0}
                width={600}
                height={200}
                font="Montserrat"
                fontSize={(word) => Math.log2(word.value) * 10}
                spiral="rectangular"
              />
            ) : (
              <div className="no-cloud">No keywords available</div>
            )}
          </div>
        </div>
      </>
    );
  } else {
    return <BasicLoader />;
  }

  function CustomTooltip({ active, payload, label }) {
    if (active) {
      return (
        <div className="bg-white p-2 text-xs">
          <span className="font-bold">{MONTHS[label - 1]} - </span>
          {Number(payload[0].value).toLocaleString(2)}{" "}
        </div>
      );
    }
    return null;
  }
}
