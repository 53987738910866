import { useState, useEffect, useCallback, useContext } from "react";
import { AppContext } from "../../Contexts/AppContexts";

import { BasicLoader, SectionHeader, CommonError } from "../Utils/Common";
import { API_BASE_URL } from "../../Constants/Contants";

import { VscRefresh } from "react-icons/vsc";

export const AiGetCompletion = () => {
  let [isLoaded, setIsLoaded] = useState(false);
  let [content, setContent] = useState(false);

  const { queryString, amaOptions } = useContext(AppContext);

  const getResults = useCallback(() => {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

    const formdata = new FormData();
    let fdata = amaOptions;
    fdata["promt"] = queryString;
    formdata.append("options", JSON.stringify(fdata));

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
    };

    fetch(API_BASE_URL + "/ai/completions", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setContent(data);
        setIsLoaded(true);
        console.log("AMA -  " + queryString + " was fetched");
      });
  }, [queryString]);

  useEffect(() => {
    getResults();
    setIsLoaded(false);
  }, [queryString, getResults]);

  function displayResults() {
    let aiResponse;

    if (content.result.status_code === 200) {
      aiResponse = content.result.response;
    } else {
      aiResponse = <CommonError message={content.result.status_message} />;
    }

    return (
      <>
        <div id="ama">
          <SectionHeader title="Ask Me Anything" ToolTipTitle="Ask Me Anything" ToolTipPointer="ask_me_anything" />
          <div className="ama-response">{aiResponse}</div>

          {content.result.status_code === 200 ? (
            <div
              className="regen-btn"
              onClick={() => {
                setIsLoaded(false);
                getResults();
              }}
            >
              <VscRefresh />
              Regenerate Response
            </div>
          ) : (
            ""
          )}
        </div>
      </>
    );
  }

  return isLoaded ? (
    <>{displayResults()}</>
  ) : (
    <>
      <SectionHeader title="Ask Me Anything" ToolTipTitle="Ask Me Anything" ToolTipPointer="ask_me_anything" />
      <BasicLoader />
    </>
  );
};
