import { useState, useEffect, useCallback, useContext } from "react";
import { MdOutlineNorthEast, MdThumbUpOffAlt, MdOutlineModeComment } from "react-icons/md";

import { BasicLoader, CommonError, ZeroResults, fetchHeaders } from "../../Utils/Common";
import { API_BASE_URL } from "../../../Constants/Contants";

export const Videos = ({ q, type }) => {
  let [isLoaded, setIsLoaded] = useState(false);
  let [content, setContent] = useState(false);

  const getYouTubeVideos = useCallback(() => {
    fetch(API_BASE_URL + "/analyze/youtube-videos?q=" + q + "&type=" + type, fetchHeaders())
      .then((response) => response.json())
      .then((data) => {
        setContent(data);
        setIsLoaded(true);
        console.log("YouTube Videos Fetched");
      });
  }, [q, type]);

  useEffect(() => {
    if (!content) {
      getYouTubeVideos();
    }
  }, [q, content, getYouTubeVideos]);

  function displayVideos() {
    let listItems;

    // Check if there is no error
    if (content.result.status_code !== 200) {
      if (content.result.status_code === 600) {
        listItems = <ZeroResults />;
      } else {
        listItems = <CommonError message={content.result.status_message} />;
      }
    } else {
      listItems = content.result.response.items.map((x, index) => {
        if (type === "video") {
          return <Video key={index} info={x} />;
        } else {
          return <Channel key={index} info={x} />;
        }
      });
    }

    if (type === "video") {
      return <>{listItems}</>;
    } else {
      return <>{listItems}</>;
    }
  }

  const Video = ({ info }) => {
    return (
      <div className="yt-a-1">
        <div className="yt-a-1-1">
          <img src={info.snippet.thumbnails.medium.url} alt={q} />
        </div>
        <div className="yt-a-1-2">
          <h3 className="yt-title">
            <a href={`https://www.youtube.com/watch?v=${info.id}`} target="_blank" rel="noreferrer">
              <span>{info.snippet.title.substring(0, 45)} ...</span>
              <MdOutlineNorthEast className="ext-link" />
            </a>
          </h3>
          <div className="yt-stat">
            <span>{Number(info.statistics.viewCount).toLocaleString()} views </span>

            <span>
              <MdThumbUpOffAlt />
              {Number(info.statistics.likeCount).toLocaleString()}
            </span>
            <span>
              <MdOutlineModeComment />
              {Number(info.statistics.commentCount).toLocaleString()}
            </span>
          </div>
          <div className="yt-desc">{info.snippet.description.substring(0, 150)}...</div>
        </div>
      </div>
    );
  };

  const Channel = ({ info }) => {
    return (
      <div className="yt-a-1">
        <div className="yt-a-1-1">
          <img src={info.snippet.thumbnails.medium.url} alt={q} />
        </div>
        <div className="yt-a-1-2">
          <h3 className="yt-title">
            <a href={`https://www.youtube.com/watch?v=${info.id}`} target="_blank" rel="noreferrer">
              <span>{info.snippet.title.substring(0, 45)} ...</span>
              <MdOutlineNorthEast className="ext-link" />
            </a>
          </h3>
          <div className="yt-stat">
            <span>{Number(info.statistics.viewCount).toLocaleString()} views </span>

            <span>{Number(info.statistics.subscriberCount).toLocaleString()} subscribers </span>

            <span>{Number(info.statistics.videoCount).toLocaleString()} videos</span>
          </div>
          <div className="yt-desc">{info.snippet.description.substring(0, 150)}...</div>
        </div>
      </div>
    );
  };

  if (isLoaded) {
    return <>{displayVideos()}</>;
  } else {
    return (
      <div className="yt-a-1">
        <BasicLoader />
      </div>
    );
  }
};
