import { useState, useEffect } from "react";
import { BasicLoader } from "../../Utils/Common";

import SavedNiche from "./SavedNiche";
import UserProfile from "./UserProfile";

import { MdClose } from "react-icons/md";

function AccountSideMenu({ loaded }) {
  let [isLoaded, setIsLoaded] = useState(loaded);
  let [content, setContent] = useState("");

  function getUser() {
    setContent(JSON.parse(localStorage.getItem("user_info")));
    setIsLoaded(true);
  }

  useEffect(() => {
    if (isLoaded === false) {
      getUser();
    }
  }, [content]);

  function hideSideMenu() {
    document.getElementById("acc-side-menu").classList.add("hide");
  }

  if (isLoaded) {
    return (
      <div
        id="acc-side-menu"
        className="hide"
        tabIndex={1}
        onBlur={() => {
          hideSideMenu();
        }}
      >
        <div
          id="side-nav-close"
          className="close-menu"
          tabIndex={2}
          onClick={() => hideSideMenu()}
        >
          <MdClose />
        </div>
        <div className="mb-6">
          <UserProfile content={content} />
        </div>
        <div>
          <SavedNiche />
        </div>
      </div>
    );
  } else {
    return (
      <div id="acc-side-menu" className="hide">
        <div
          id="side-nav-close"
          className="close-menu"
          tabIndex={2}
          onClick={() => hideSideMenu()}
        >
          <MdClose />
        </div>
        <BasicLoader />
      </div>
    );
  }
}

export default AccountSideMenu;
