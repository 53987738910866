import { useState } from "react";
import { API_BASE_URL } from "../../../Constants/Contants";
import { SectionHeader } from "../../Utils/Common";

import { Buffer } from "buffer";

const PdfBranding = ({ content }) => {
  const [logo, setLogo] = useState(content.pdf_branding.logo);
  const [blurb, setBlurb] = useState(content.pdf_branding.blurb);
  const [footer, setFooter] = useState(content.pdf_branding.footer);

  const onFileChange = (event) => {
    if (event.target.files[0]) {
      var reader = new FileReader();

      reader.onload = function (e) {
        setLogo(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const onBlurbChange = (event) => {
    setBlurb(event.target.value);
  };

  const onFooterChange = (event) => {
    setFooter(event.target.value);
  };

  let myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

  const formdata = new FormData();
  formdata.append("logo", logo);
  formdata.append("blurb", blurb);
  formdata.append("footer", footer);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
    body: formdata,
  };

  function chooseFile() {
    document.getElementById("upload_btn").click();
  }

  function previewReport() {
    // Update Progress
    document.getElementById("preview").innerHTML =
      '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>';

    fetch(API_BASE_URL + "/pdf/preview", requestOptions)
      .then((res) => res.arrayBuffer())
      .then((data) => {
        var base64Str = Buffer.from(data).toString("base64");

        var binaryString = window.atob(base64Str);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
          var ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }
        var arrBuffer = bytes;

        var newBlob = new Blob([arrBuffer], { type: "application/pdf" });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }

        data = window.URL.createObjectURL(newBlob);
        let fileName = "preview.pdf";

        // Download the file
        let a = document.createElement("a");
        a.setAttribute("download", fileName);
        a.setAttribute("href", data);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        document.getElementById("preview").innerHTML = "Preview Report";
      });
  }

  function updateReportBranding() {
    document.getElementById("update").innerHTML =
      '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>';

    fetch(API_BASE_URL + "/pdf/update", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        localStorage.setItem("user_info", JSON.stringify(data.result));

        document.getElementById("update").innerHTML = "Update Report Branding";
      });
  }

  const ReportExport = () => {
    if (content.details.access_level === "pro") {
      return (
        <div className="btn-wrap">
          <div id="update" onClick={() => updateReportBranding()}>
            Update Report Branding
          </div>
          <div id="preview" onClick={() => previewReport()}>
            Preview Report
          </div>
        </div>
      );
    } else {
      return (
        <div className="btn-wrap">
          <span className="pdf-info">
            Report customization is not available to Basic User.
          </span>
        </div>
      );
    }
  };

  return (
    <>
      <div id="pdf-branding">
        <SectionHeader title="Report Branding" />

        <div className="pdf-branding">
          <div className="pdf-entry">
            <p className="pdf-label">Logo</p>

            <div className="pdf-img-wrap" href="#" onClick={() => chooseFile()}>
              <img id="pdf-img" src={logo} alt="logo" />
            </div>

            <p className="pdf-description">
              Click the image to upload a new logo. Preferred size: 800px X
              630px
            </p>
          </div>
          <div className="pdf-entry">
            <p className="pdf-label">Blurb</p>
            <textarea
              id="pdf-blurb"
              rows="3"
              defaultValue={blurb}
              onChange={onBlurbChange}
            ></textarea>
            <p className="pdf-description">Blurb below logo, accepts html</p>
          </div>
          <div className="pdf-entry">
            <p className="pdf-label">Footer</p>
            <textarea
              id="pdf-footer"
              rows="3"
              defaultValue={footer}
              onChange={onFooterChange}
            ></textarea>
            <p className="pdf-description">Footer Copyright</p>
          </div>

          <div className="col-12">
            <div className="upload-status hide-me"></div>
          </div>
          <input
            hidden
            id="upload_btn"
            type="file"
            name="file"
            accept="image/png, image/jpeg"
            onChange={onFileChange}
          />
          <ReportExport />
        </div>
      </div>
    </>
  );
};

export default PdfBranding;
