import { MdOutlineNorth, MdStarRate, MdStarBorder, MdStarHalf, MdOutlineErrorOutline, MdHelp, MdClear } from "react-icons/md";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useFetch = (url) => {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const abortCont = new AbortController();

    setTimeout(() => {
      fetch(url, { signal: abortCont.signal })
        .then((res) => {
          if (!res.ok) {
            // error coming back from server
            throw Error("could not fetch the data for that resource");
          }
          return res.json();
        })
        .then((data) => {
          setIsPending(false);
          setData(data);
          setError(null);
        })
        .catch((err) => {
          if (err.name === "AbortError") {
            console.log("fetch aborted");
          } else {
            // auto catches network / connection error
            setIsPending(false);
            setError(err.message);
          }
        });
    }, 1000);

    // abort the fetch
    return () => abortCont.abort();
  }, [url]);

  return { data, isPending, error };
};

export const fetchHeaders = () => {
  let myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

  let requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return requestOptions;
};

export const BasicLoader = () => {
  return (
    <div id="niche-loader">
      <div className="lds-hourglass"></div>
    </div>
  );
};

export const MiniLoader = () => {
  return (
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export const BasicPrompt = ({ promt }) => {
  const basicPromt = (
    <span>
      Please enter a <strong> keyword </strong> then press enter to begin search ...
    </span>
  );

  const promtMessage = promt ? promt : basicPromt;

  return (
    <div id="basic-promt" className="max-w-4xl mx-auto relative z-10">
      <MdOutlineNorth className=" mx-10 mb-3 relative text-5xl text-orange-500" />
      <div className="w-fit bg-orange-100 rounded-lg text-slate-500 text-sm shadow-sm px-4 py-2 ">{promtMessage}</div>
    </div>
  );
};

export const ZeroResults = () => {
  return (
    <div id="basic-promt" className="relative py-5 w-full">
      <div className="w-fit bg-red-500 rounded-lg text-white text-sm shadow-sm px-4 py-2 max-w-4xl mx-auto">
        No Results Found!
      </div>
    </div>
  );
};

export const CommonError = ({ message }) => {
  // let mes = message ? message : "Unknown error.";

  let nav = useNavigate();

  function logOut() {
    nav("/logout");
  }

  if (message) {
    return (
      <div id="error-promt" className="max-w-4xl mx-auto py-5">
        <div className="w-fit bg-red-500 rounded-lg text-white text-sm shadow-sm px-4 py-2 max-w-4xl mx-auto">{message}</div>
      </div>
    );
  } else {
    // Looks like we have a failed token, let's logout and send to login
    return (
      <div id="error-promt" className="max-w-4xl mx-auto">
        <div className="max-w-2xl mx-auto">
          <div className="max-w-lg mx-auto">
            <div id="bot-failed"></div>
            <div className="flex items-center bg-red-600 py-5 px-12 rounded-full text-white text-lg mb-6">
              <div className="basis-20 shrink-0 text-6xl">
                <MdOutlineErrorOutline />
              </div>
              <div>Looks like your login information had expired. Please click the button below to fix the issue.</div>
            </div>
            <div className="text-center">
              <button
                onClick={logOut}
                className="uppercase py-6 px-12 mx-auto text-center bg-orange-600 mt-6 rounded-full text-white hover:bg-orange-800"
              >
                Fix Login Information
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export const SectionHeader = ({ title, ToolTipTitle, ToolTipPointer }) => {
  let sectionTitle = title ? title : "Unknown error.";

  return (
    <h3 className="section-header">
      {sectionTitle}

      {ToolTipTitle ? <ToolTipHelper title={ToolTipTitle} pointer={ToolTipPointer} /> : ""}
    </h3>
  );
};

export const Rating = ({ rating }) => {
  let stars = rating ? rating : 0;

  let whole = Math.floor(stars);
  let half = stars % 1 < 0.5 ? 0 : 1;
  let blank = 5 - Number(whole + half);

  return (
    <div className="star-rating">
      {[...Array(whole)].map((e, i) => (
        <MdStarRate key={i} className="inline" />
      ))}
      {[...Array(half)].map((e, i) => (
        <MdStarHalf key={i} className="inline" />
      ))}
      {[...Array(blank)].map((e, i) => (
        <MdStarBorder key={i} className="inline" />
      ))}
    </div>
  );
};

export const keyGen = () => {
  let k = "abcdefg hijk lmn opq rstu v wzyz 129635148";
  return k
    .split("")
    .sort(function () {
      return 0.5 - Math.random();
    })
    .join("")
    .replace(/\s/g, "")
    .substring(1, 12);
};

export const ToolTipHelper = ({ title, pointer }) => {
  // let sectionTitle = title ? title : "Helper";
  let tooltip = JSON.parse(localStorage.getItem("tooltip"));

  function showToolTip(params) {
    let el = document.querySelector('div[tooltip-data="' + pointer + '"]');

    el.classList.toggle("hide");
    if (!el.classList.contains("hide")) {
      el.focus();
    }
  }

  function text() {
    if (pointer === "keyword_difficulty") {
      return (
        <div className="kd-pop">
          Difficulty of ranking in the first top-10 organic results.
          <div className="dark-green difficulty">Very Easy</div>
          <div className="light-green difficulty">Easy</div>
          <div className="yellow difficulty">Difficult</div>
          <div className="orange difficulty">Very Difficult</div>
          <div className="red difficulty">Extreme Competition</div>
        </div>
      );
    } else {
      return tooltip[pointer];
    }
  }

  return (
    <div className="tt-wrap ">
      <MdHelp className="ms-icon" onClick={() => showToolTip()} />
      <div
        className="tooltip hide"
        tooltip-data={pointer}
        tabIndex={-1}
        onBlur={() => {
          showToolTip();
        }}
      >
        {/* <div className="arrow-up"></div> */}
        {/* <div className="tt-header">
          {sectionTitle}{" "}
          <MdClear
            className="tt-close"
            tabIndex={1}
            onClick={() => showToolTip()}
          />
        </div> */}
        <div className="tt-body">{text()}</div>
      </div>
    </div>
  );
};

export function difficultyColor(difficulty) {
  // Very Easy
  if (difficulty < 21) {
    return "dark-green";
  }

  // Easy
  if (difficulty < 41) {
    return "light-green";
  }

  // Difficult
  if (difficulty < 61) {
    return "yellow";
  }

  // Easy
  if (difficulty < 81) {
    return "orange";
  }

  // Extreme Competition
  if (difficulty < 110) {
    return "red";
  }
}

export function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: " k" },
    { value: 1e6, symbol: " M" },
    { value: 1e9, symbol: " B" },
    { value: 1e12, symbol: " T" },
    { value: 1e15, symbol: " P" },
    { value: 1e18, symbol: " E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}
