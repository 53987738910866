import { useContext } from "react";

import { AppContext } from "../../Contexts/AppContexts";

const SubmitButton = () => {
  const { mode, setQueryString, setDoSearch, doSearch } = useContext(AppContext);

  let btnLabel;

  if (mode === "ask") {
    btnLabel = "Generate Questions";
  } else if (mode === "expand") {
    btnLabel = "Generate Keywords";
  } else if (mode === "analyze") {
    btnLabel = "Analyze Keyword";
  } else if (mode === "ama") {
    btnLabel = "Ask Me";
  } else {
    btnLabel = "Discover Keyword";
  }

  function sendResults(evt) {
    evt.preventDefault();
    setDoSearch(true);
    setQueryString(document.getElementById("keyword-textbox").value);
  }

  //   return doSearch === false ? (
  //     <div className="sf-3">
  //       <input
  //         type="submit"
  //         value={btnLabel}
  //         className="search-btn active"
  //         onClick={(evt) => {
  //           sendResults(evt);
  //         }}
  //       ></input>
  //     </div>
  //   ) : (
  //     <div className="sf-3">
  //       <input
  //         type="submit"
  //         value={btnLabel}
  //         className="search-btn inactive"
  //         onClick={(evt) => {
  //           evt.preventDefault();
  //         }}
  //       ></input>
  //     </div>
  //   );

  return (
    <div className="sf-3">
      <input
        type="submit"
        value={btnLabel}
        className="search-btn active"
        onClick={(evt) => {
          sendResults(evt);
        }}
      ></input>
    </div>
  );
};

export default SubmitButton;
