import { useContext } from "react";

import { AnalyzeContext } from "../../Contexts/AnalyzeContexts";
import { AppContext } from "../../Contexts/AppContexts";

import { SectionHeader } from "../Utils/Common";

export const Facebook = () => {
  let { queryString } = useContext(AppContext);

  return (
    <>
      <div id="fb">
        <div id="fb-g">
          <SectionHeader title="Top Facebook Groups" ToolTipTitle="Top Facebook Groups" ToolTipPointer="fb_group" />
          <p>You must be logged-in to your facebook account before clicking the button below to view the results.</p>
          <a className="btn-2" href={`https://www.facebook.com/search/groups/?q=${queryString}`} target="_blank" rel="noreferrer">
            Top Facebook Groups
          </a>
        </div>
        <div id="fb-p">
          <SectionHeader title="Top Facebook Page" ToolTipTitle="Top Facebook Pages" ToolTipPointer="fb_page" />
          <p>You must be logged-in to your facebook account before clicking the button below to view the results.</p>
          <a className="btn-2" href={`https://www.facebook.com/search/pages/?q=${queryString}`} target="_blank" rel="noreferrer">
            Top Facebook Pages
          </a>
        </div>
      </div>
    </>
  );
};
