import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { MdError } from "react-icons/md";

import { APP_DATA_URL } from "../../Constants/Contants";

import logo from "../../images/ib-h.svg";

export default function Login() {
  let [username, setUsername] = useState("");
  let [password, setPassword] = useState("");
  let [password1, setPassword1] = useState("");
  let [password2, setPassword2] = useState("");
  let [code, setCode] = useState("");
  let [email, setEmail] = useState("");
  let [status, setStatus] = useState("");
  let [loginMode, setLoginMode] = useState("login");
  let [greenStatus, setGreenStatus] = useState("");
  let [codeTry, setCodeTry] = useState(0);

  let navigate = useNavigate();

  // LOGIN
  function submitLogin(e) {
    e.preventDefault();

    if (!username) {
      setStatus("Username cannot be empty");
      setGreenStatus("");
      return;
    }

    if (!password) {
      setStatus("Password cannot be empty");
      setGreenStatus("");
      return;
    }

    setGreenStatus("Logging in");
    setStatus("");

    const formdata = new FormData();
    formdata.append("username", username);
    formdata.append("password", password);

    const requestOptions = {
      method: "POST",
      body: formdata,
    };

    // Generate Token
    fetch(APP_DATA_URL + "/wp-json/jwt-auth/v1/token", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        // return;
        // Let's check the data
        if (data.token) {
          // Let's save this locally
          localStorage.setItem("token", data.token);
          localStorage.setItem("tooltip", JSON.stringify(data.tooltip));

          localStorage.setItem(
            "user_info",
            JSON.stringify(data.user_info.result)
          );

          console.log(data);

          setStatus("");
          setGreenStatus("Login successfull! Accessing dashboard ...");

          // Let's grab the user details
          // fetch(API_BASE_URL + "/user", fetchHeaders())
          //   .then((response) => response.json())
          //   .then((data) => {
          //     localStorage.setItem("user_info", JSON.stringify(data.result));

          //     setInterval(() => {
          //       // window.location.replace("/");
          //     }, 500);
          //   });

          setInterval(() => {
            window.location.replace("/");
          }, 500);
        } else {
          // Looks like this user has false info
          setGreenStatus("");
          setStatus("The username or password is incorrect! Please try again!");
        }
      })
      .catch(function (error) {
        // Looks like this user has false info
        setGreenStatus("");
        setStatus("The username or password is incorrect! Please try again!");
      });
  }

  // RESET PASSWORD REQUEST
  function submitReset(e) {
    e.preventDefault();

    setGreenStatus("");
    setCodeTry(0);

    if (!email) {
      setStatus("Please supply username or email");
      return;
    }

    setGreenStatus("Verifying Information...");
    setStatus("");

    setEmail(email);

    const formdata = new FormData();
    formdata.append("email", email);

    const requestOptions = {
      method: "POST",
      body: formdata,
    };

    // Generate Token
    fetch(APP_DATA_URL + "/wp-json/bdpwr/v1/reset-password", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // Let's check the data
        if (data.data.status === 200) {
          setStatus("");
          setGreenStatus("");
          setLoginMode("set");
        } else {
          // Looks like this user has false info
          setGreenStatus("");
          setStatus(
            "Looks like we are having trouble resetting your password. Please contact support to solve the issue."
          );
        }
      })
      .catch(function (error) {
        // Looks like this user has false info
        setGreenStatus("");
        setStatus(
          "Looks like we are having trouble resetting your password. Please contact support to solve the issue."
        );
      });
  }

  // SET NEW PASSWORD
  function submitPassword(e) {
    e.preventDefault();

    if (!password1) {
      setStatus("Password cannot be empty");
      return;
    }

    if (!password2) {
      setStatus("Please confirm password");
      return;
    }

    // Check if password meet the criteria
    // Regex to check if a string contains uppercase, lowercase, special character & numeric value
    let pattern = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
    );

    if (pattern.test(password1)) {
    } else {
      setStatus(
        "Invalid password format! Must contain uppercase, lowercase, special character & numeric value"
      );
      return;
    }

    // Check if passwords match
    if (password1 !== password2) {
      setStatus("Password mismatch! Please supply same passwords.");
      return;
    }

    // Check password length
    if (password1.length < 8) {
      setStatus("Password must be at least 8 characters long.");
      return;
    }

    // Check code
    if (!code) {
      setStatus("Please supply the Reset Code");
      return;
    }

    setGreenStatus("Verifying Information...");
    setStatus("");

    const formdata = new FormData();
    formdata.append("email", email);
    formdata.append("code", code);
    formdata.append("password", password1);

    const requestOptions = {
      method: "POST",
      body: formdata,
    };

    // Generate Token
    fetch(APP_DATA_URL + "/wp-json/bdpwr/v1/set-password", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setStatus("");
        setGreenStatus("");

        // Let's check the data
        if (data.data.status === 200) {
          setLoginMode("passChange");
        }

        // Invalid Code
        if (data.data.status === 501) {
          let c = codeTry + 1;

          if (c > 2) {
            setStatus(data.message);
            setLoginMode("setMax");
            return;
          } else {
            setCodeTry(c);
          }

          setStatus(data.message);
          return;
        }

        // Any other error
        if (data.data.status === 500) {
          setStatus("");
          setStatus(data.message);
          setGreenStatus("");
        }
      })
      .catch(function (error) {
        // Looks like this user has false info
        setGreenStatus("");
        setStatus(
          "Looks like we are having trouble resetting your password. Please contact support to solve the issue."
        );
      });
  } // submitReset

  function loginForm() {
    // Login
    if (loginMode === "login") {
      return (
        <>
          <h1 className="fw-b">Login</h1>
          <form onSubmit={submitLogin} className="fw-form">
            <input
              type="text"
              name="username"
              id="username"
              placeholder="Username"
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
            <input
              type="password"
              name="password"
              id="password"
              placeholder="Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <button type="submit" id="submit-btn">
              Login
            </button>
          </form>
          <p
            className="fw-d"
            onClick={() => {
              setLoginMode("reset");
            }}
          >
            Lost your password?
          </p>
        </>
      );
    }

    // Reset Password
    if (loginMode === "reset") {
      return (
        <>
          <h1 className="fw-b">Reset Password</h1>
          <form onSubmit={submitReset} className="fw-form">
            <input
              type="text"
              name="email"
              id="email"
              placeholder="Username or Email Address"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <button type="submit" id="submit-btn">
              Get New Password
            </button>
          </form>
          <p
            className="fw-d"
            onClick={() => {
              setLoginMode("login");
            }}
          >
            Back to login.
          </p>
        </>
      );
    }

    // Set password
    if (loginMode === "set") {
      return (
        <>
          <h1 className="fw-b">Create New Password</h1>
          <p className="fw-info">
            If you entered a correct username / email, a password reset code
            will be sent to your email address. Please use the code below to set
            your new password.
          </p>
          <form onSubmit={submitPassword} className="fw-form">
            <input
              type="password"
              name="pass1"
              id="pass1"
              placeholder="New Password"
              value={password1}
              onChange={(e) => {
                setPassword1(e.target.value);
              }}
            />

            <input
              type="password"
              name="pass2"
              id="pass2"
              placeholder="Confirm Password"
              value={password2}
              onChange={(e) => {
                setPassword2(e.target.value);
              }}
            />

            <input
              type="text"
              name="code"
              id="code"
              placeholder="Reset Code"
              value={code}
              onChange={(e) => {
                setCode(e.target.value);
              }}
            />

            <button type="submit" id="submit-btn">
              Set Password
            </button>
          </form>
          <p
            className="fw-d"
            onClick={() => {
              setLoginMode("login");
            }}
          >
            Back to login.
          </p>
        </>
      );
    }

    // Max Retry
    if (loginMode === "setMax") {
      return (
        <>
          <h1 className="fw-b">Reset Password Failed!</h1>

          <p
            className="fw-d"
            onClick={() => {
              setLoginMode("login");
            }}
          >
            Back to login.
          </p>
        </>
      );
    }

    // Password retry max
    if (loginMode === "passChange") {
      return (
        <>
          <h1 className="fw-b">Password reset successfully!</h1>

          <p
            className="fw-d"
            onClick={() => {
              setLoginMode("login");
            }}
          >
            Back to login.
          </p>
        </>
      );
    }
  }

  return (
    <div id="login">
      <div className="login-wrap">
        <div className="form-wrap">
          <img src={logo} className="fw-a" alt="logo" />

          {loginForm()}

          {status ? (
            <div className="fw-error">
              <MdError />
              {status}
            </div>
          ) : (
            ""
          )}

          {greenStatus ? (
            <div className="fw-success">
              <MdError />
              {greenStatus}
            </div>
          ) : (
            ""
          )}

          <p className="fw-e">
            Trouble Logging In? Click{" "}
            <a
              href="/contact"
              onClick={() => {
                navigate("/contact");
              }}
              className="fw-f"
            >
              here
            </a>{" "}
            to contact us.
          </p>
        </div>

        <div className="login-b">
          <p className="login-b-1">
            Not yet a user?
            <a
              href="https://ideabot.ai/"
              target="_blank"
              rel="noreferrer"
              className="login-b-2"
            >
              Register here ...
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
