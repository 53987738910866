import { useState, useEffect, useContext } from "react";

import { AppContext } from "../../Contexts/AppContexts";
import { ResponsiveContainer, BarChart, Bar } from "recharts";
import { BsQuestion, BsCollection, BsBarChartLine } from "react-icons/bs";
import { WiCloudRefresh } from "react-icons/wi";

import { KeywordDifficulty } from "../Analyze/Keyword-Difficulty";
import { difficultyColor } from "../Utils/Common";

export const KeywordEntry = (props) => {
  let [difficulty, setDifficulty] = useState(props.data.difficulty);
  const { setMode, setDoSearch, setQueryString } = useContext(AppContext);

  const doAction = (action, string) => {
    setMode(action);
    setDoSearch(true);
    setQueryString(string);
  };

  useEffect(() => {
    if (props.data.difficulty === "--") {
      setDifficulty(
        <WiCloudRefresh
          className="ks-rd"
          onClick={() => {
            setDifficulty(
              <KeywordDifficulty
                keyword={props.data.keyword}
                updateList={true}
              />
            );
          }}
        />
      );
    }
  }, [props]);

  return (
    <>
      <div className="k-a">
        <ResponsiveContainer>
          <BarChart data={props.data.dataSet.slice(0).reverse()}>
            <Bar
              dataKey="search_volume"
              fill="#CBD5E1"
              isAnimationActive={false}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className="k-b">
        <BsQuestion
          className="iconCss"
          onClick={() => doAction("ask", props.data.keyword)}
          title="Generate Questions"
        />
        <BsCollection
          className="iconCss"
          onClick={() => doAction("expand", props.data.keyword)}
          title="Generate Keywords"
        />
        <BsBarChartLine
          className="iconCss"
          onClick={() => doAction("analyze", props.data.keyword)}
          title="Analyze Keyword"
        />
        {props.data.keyword}
      </div>
      <div className="k-c ">{Number(props.data.sv).toLocaleString(2)}</div>
      <div className="k-d ">{Number(props.data.cpc).toFixed(2)}</div>
      <div className="k-e">
        <div className={difficultyColor(props.data.difficulty) + " difficulty"}>
          {difficulty}
        </div>
      </div>
    </>
  );
};
