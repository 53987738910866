import { useState, useEffect } from "react";

import {
  BasicLoader,
  CommonError,
  Rating,
  ZeroResults,
  fetchHeaders,
} from "../../Utils/Common";
import { MdOutlineNorthEast } from "react-icons/md";

import { API_BASE_URL } from "../../../Constants/Contants";

export const Products = ({ q, merchant }) => {
  let [isLoaded, setIsLoaded] = useState(false);
  let [content, setContent] = useState("");
  let [retryCount, setRetryCount] = useState(0);

  const getContent = () => {
    fetch(
      API_BASE_URL + "/analyze/merchant?merchant=" + merchant + "&q=" + q,
      fetchHeaders()
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.result.status_code === 200) {
          setContent(data.result);
          setIsLoaded(true);

          console.log(`${merchant} Shopping Fetched`);
        } else {
          if (retryCount < 5) {
            setRetryCount(++retryCount);
            setTimeout(function () {
              console.log(
                `Retrying ${merchant} Shopping for ${retryCount} times`
              );
              getContent();
            }, 5000);
          } else {
            setContent(data.result);
            setIsLoaded(true);
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    getContent();
  }, [q]);

  function AmazonProduct({ data }) {
    let bg = data.image_url ? data.image_url : "";
    let style = {
      backgroundImage: `url(${bg})`,
      backgroundSize: "cover",
    };

    let ratingVal = data?.rating?.value ?? 0;
    let votesCount = data?.rating?.votes_count ?? 0;

    return (
      <div className="m-entry">
        <div className="amz-1" style={style}></div>
        <div className="amz-2">
          <a href={data.url} target="_blank" rel="noreferrer">
            {data.title.substring(0, 150)} ...
            <MdOutlineNorthEast className="ext-link" />
          </a>
        </div>
        <div className="amz-3">
          <Rating rating={ratingVal} />
        </div>
        <div className="amz-4">{Number(votesCount).toLocaleString()}</div>
      </div>
    );
  }

  function GoogleProduct({ data }) {
    let bg = data.product_images ? data.product_images : "";
    let style = {
      backgroundImage: `url(${bg})`,
      backgroundSize: "cover",
    };

    let rating = data.product_rating ? data.product_rating.value : 0;
    let reviewsCount = data.reviews_count ? data.reviews_count : 0;

    return (
      <div className="m-entry">
        <div className="amz-1" style={style}></div>
        <div className="amz-2">
          <a href={data.shopping_url} target="_blank" rel="noreferrer">
            {data.title.substring(0, 150)} ...
            <MdOutlineNorthEast className="ext-link" />
          </a>
        </div>
        <div className="amz-3">
          <Rating rating={rating} />
        </div>
        <div className="amz-4">{Number(reviewsCount).toLocaleString()}</div>
      </div>
    );
  }

  function getProducts() {
    let listItems;

    // Let's check for valid returns
    if (content.status_code !== 200) {
      if (content.status_code === 600) {
        listItems = <ZeroResults />;
      } else {
        listItems = <CommonError message={content.status_message} />;
      }
    } else {
      let productKeys = [""];

      // Let's make sure we have a list
      if (!content.response) {
        return null;
      }

      listItems = content.response.items.map((x, index) => {
        if (productKeys.length <= 10) {
          // AMAZON
          if (merchant === "amazon") {
            if (
              x.type === "amazon_serp" &&
              !productKeys.includes(x.data_asin) &&
              x.data_asin
            ) {
              if (x.data_asin) {
                productKeys.push(x.data_asin);
              }

              return <AmazonProduct key={x.data_asin} data={x} />;
            }
          }

          //  GOOGLE
          if (merchant === "google") {
            if (
              x.type === "google_shopping_serp" &&
              !productKeys.includes(x.product_id) &&
              x.product_id
            ) {
              if (x.product_id) {
                productKeys.push(x.product_id);
              }
              return <GoogleProduct key={x.product_id} data={x} />;
            }
          }
        }

        return null;
      });
    }

    return (
      <div id={merchant}>
        <div className="m-header">
          <div className="amz-1">Image</div>
          <div className="amz-2">Title</div>
          <div className="amz-3">Rating</div>
          <div className="amz-4">Reviews</div>
        </div>
        <div className="m-entries">{listItems}</div>
      </div>
    );
  }

  if (isLoaded) {
    return getProducts();
  } else {
    return <BasicLoader />;
  }
};
