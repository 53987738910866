import { BiCaretDown, BiCheck } from "react-icons/bi";
import { useState, useContext, useEffect, useRef } from "react";

import { AppContext } from "../../Contexts/AppContexts";

const ExpandModeSelector = () => {
  let [toggleMode, setToggleMode] = useState(false);
  const { expandMode, setExpandMode, setDoSearch } = useContext(AppContext);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setToggleMode(false);
        }
      }

      if (!toggleMode) {
        document.removeEventListener("click", handleClickOutside, true);
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const Mode = ({ toggle }) => {
    if (!toggle) {
      return null;
    }

    const modes = ["ideas", "related", "suggest"];

    return (
      <div className="drop-selector mode-selector" ref={wrapperRef}>
        <div
          className="ms-entries"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          {modes.map((mode, index) => {
            return <Entry key={index} mode={mode} />;
          })}
        </div>
      </div>
    );
  };

  const Entry = ({ mode }) => {
    let a = mode === expandMode ? "active" : "";

    return (
      <div
        className={a + " ms-entry dropdown-entry"}
        role="menuitem"
        onClick={() => {
          setExpandMode(mode);
          setToggleMode(false);
          setDoSearch(false);
        }}
      >
        {mode} {mode === expandMode && <BiCheck />}
      </div>
    );
  };

  return (
    <div className="sf-4">
      <button
        onClick={() => {
          setToggleMode(!toggleMode);
        }}
        type="button"
        className="dropdown-btn"
        id="options-menu"
        aria-haspopup="true"
        aria-expanded="true"
      >
        {expandMode} <BiCaretDown />
      </button>

      {toggleMode && <Mode toggle={toggleMode} />}
    </div>
  );
};
export default ExpandModeSelector;
