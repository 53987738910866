import { useContext } from "react";

import { DiscoverContext } from "../../Contexts/DiscoverContexts";
import { CommonError, SectionHeader, ZeroResults } from "../Utils/Common";
import { BsQuestion, BsCollection, BsBarChartLine } from "react-icons/bs";
import { AppContext } from "../../Contexts/AppContexts";

const NichesList = ({ data }) => {
  const { setDisplayMode, nichesCategoryData } = useContext(DiscoverContext);
  const { setMode, setDoSearch, setQueryString } = useContext(AppContext);

  const doAction = (action, string) => {
    setMode(action);
    setDoSearch(true);
    setQueryString(string.replace(/&amp;/g, "&").replace(/&#038;/g, "&"));
  };

  function displayNicheList() {
    let listItems;

    if (nichesCategoryData.status_code !== 200) {
      if (nichesCategoryData.status_code === 600) {
        listItems = <ZeroResults />;
      } else {
        listItems = <CommonError message={nichesCategoryData.status_message} />;
      }
    } else {
      listItems = nichesCategoryData.items.map((x) => {
        return <NicheItem key={x.id} x={x} />;
      });
    }

    function NicheItem(x) {
      x = x.x;
      return (
        <div className="niche-item">
          <BsQuestion
            onClick={() => doAction("ask", x.name)}
            title="Generate Questions"
          />
          <BsCollection
            onClick={() => doAction("expand", x.name)}
            title="Generate Keywords"
          />
          <BsBarChartLine
            onClick={() => doAction("analyze", x.name)}
            title="Analyze Keyword"
          />

          {x.name.replace(/&amp;/g, "&").replace(/&#038;/g, "&")}
        </div>
      );
    }

    return (
      <>
        <h1 className="result-header">
          Niches from{" "}
          <span>{nichesCategoryData.category_name.replace(/&amp;/g, "&")}</span>{" "}
          Directory
        </h1>

        <div
          className="dn"
          onClick={() => {
            setDisplayMode("sub-category");
          }}>
          Back To Sub Category
        </div>

        <div id="sub-category-list">
          <SectionHeader
            title={nichesCategoryData.category_name.replace(/&amp;/g, "&")}
          />

          <div className="niche-list">{listItems}</div>
        </div>
      </>
    );
  }

  // The main return
  return <>{displayNicheList()}</>;
};

export default NichesList;
