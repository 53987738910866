import { MdCircle } from "react-icons/md";
import { CommonError, SectionHeader } from "../../Utils/Common";

const SearchHistory = ({ content }) => {
  if (content.details.history) {
    let listItems = content.details.history.map((x, index) => {
      return (
        <li key={index} className="sh-entry inline text-sm text-slate-600">
          {x}
          <MdCircle className="sh-1 inline mx-3 text-xs text-orange-400" />
        </li>
      );
    });

    return (
      <div id="search-history">
        <SectionHeader title="Search History" />
        <ul id="sh-entries">{listItems}</ul>
      </div>
    );
  } else {
    return (
      <div id="search-history">
        <SectionHeader title="History" />
        <CommonError message="Nothing in here ..." />
      </div>
    );
  }
};

export default SearchHistory;
