import { useState, useContext, useCallback, useEffect, useRef } from "react";

import { BiCaretDown, BiCheck } from "react-icons/bi";

import { AppContext } from "../../Contexts/AppContexts";

const CountrySelector = () => {
  const { country, setCountry, setDoSearch } = useContext(AppContext);

  let [toggleDropdown, setToggleDropdown] = useState(false);
  const defaultList = [
    {
      country_code: "US",
      location_code: "2840",
      country_name: "United States",
    },
    {
      country_code: "CA",
      location_code: "2124",
      country_name: "Canada",
    },
    {
      country_code: "AU",
      location_code: "2036",
      country_name: "Australia",
    },
    {
      country_code: "SG",
      location_code: "2702",
      country_name: "Singapore",
    },
    {
      country_code: "MY",
      location_code: "2458",
      country_name: "Malaysia",
    },
    {
      country_code: "PH",
      location_code: "2608",
      country_name: "Philippines",
    },
  ];

  let [countryList, setCountryList] = useState(defaultList);
  let [allCountryLoaded, setAllCountryLoaded] = useState(false);
  let [countryFilter, setCountryFilter] = useState("");

  // This will hold all the country list
  const newCountryList = useCallback(() => {
    let clist = JSON.parse(localStorage.getItem("countryList"));

    if (clist) {
      setCountryList(clist);
      setAllCountryLoaded(true);
    } else {
      fetch("./country.json")
        .then((response) => response.json())
        .then((data) => {
          localStorage.setItem("countryList", JSON.stringify(data));
          setCountryList(data);
          setAllCountryLoaded(true);
          console.log("All countries fetched.");
        });
    }
  }, []);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setToggleDropdown(false);
        }
      }

      if (!toggleDropdown) {
        document.removeEventListener("click", handleClickOutside, true);
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  // ==================
  const CountrySelect = ({ toggle }) => {
    if (!toggle) {
      return null;
    }

    let allCountryButton = (
      <div
        onClick={() => newCountryList()}
        className="cs-all-country"
        role="menuitem"
      >
        {" "}
        More Countries
      </div>
    );

    function filteredCountry() {
      let a = countryList.filter(function (c) {
        return (
          c.country_name.toLowerCase().indexOf(countryFilter.toLowerCase()) !==
          -1
        );
      });

      return a;
    }

    function setFilter(evt) {
      setCountryFilter(evt.target.value);
    }

    if (allCountryLoaded) {
      return (
        <div
          id="country-selector"
          className="drop-selector max"
          ref={wrapperRef}
        >
          <div
            className=""
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            <div className="cs-search">
              <input
                className=""
                type="text"
                name="country-filter"
                placeholder="Search Country ..."
                autoComplete="off"
                onChange={setFilter}
                value={countryFilter}
                autoFocus
              />
            </div>

            <div className="cs-entries">
              {filteredCountry().map((countryEntry, index) => {
                return (
                  <Country
                    key={countryEntry.country_code + index}
                    country={countryEntry}
                    isSelected={
                      countryEntry.country_name === country.country_name
                        ? true
                        : false
                    }
                  />
                );
              })}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div id="country-selector" className="drop-selector" ref={wrapperRef}>
          <div
            className=""
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            <div className="cs-entries">
              {filteredCountry().map((countryEntry, index) => {
                return (
                  <Country
                    key={countryEntry.country_code + index}
                    country={countryEntry}
                    isSelected={
                      countryEntry.country_name === country.country_name
                        ? true
                        : false
                    }
                  />
                );
              })}
            </div>
            {allCountryButton}
          </div>
        </div>
      );
    }
  };

  // =========================
  const Country = ({ country, isSelected }) => {
    let style = allCountryLoaded ? "max" : "min";
    return (
      <div
        className={`${style} cs-entry dropdown-entry  ${
          isSelected ? "active" : ""
        }`}
        role="menuitem"
        country_code={country.country_code}
        location_code={country.location_code}
        country_name={country.country_name}
        onClick={() => {
          setCountry(country);
          setDoSearch(false);
          setToggleDropdown(false);
        }}
      >
        {" "}
        {country.country_name} {isSelected && <BiCheck />}
      </div>
    );
  };

  return (
    <div className={allCountryLoaded ? "sf-2" : "sf-2"}>
      <button
        onClick={() => {
          setToggleDropdown(!toggleDropdown);
          setCountryFilter("");
        }}
        type="button"
        className="dropdown-btn"
        id="options-menu"
        aria-haspopup="true"
        aria-expanded="true"
      >
        {country.country_name} <BiCaretDown />
      </button>

      {toggleDropdown && <CountrySelect toggle={toggleDropdown} />}
    </div>
  );
};
export default CountrySelector;
