import { BsSearch } from "react-icons/bs";
import { useState, useCallback, useEffect, useContext } from "react";
import GoogleSuggest from "./GoogleSuggest";
import reactDom from "react-dom";
import fetchJsonp from "fetch-jsonp";

import { AppContext } from "../../Contexts/AppContexts";

const TextInput = () => {
  const { queryString } = useContext(AppContext);

  let [suggestList, setSuggestList] = useState([]);
  let [inputText, setinputText] = useState(queryString);

  const fetchData = useCallback((q) => {
    fetchJsonp(
      `https://suggestqueries.google.com/complete/search?client=chrome&q=${q}`
    )
      .then((response) => response.json())
      .then((data) => {
        setSuggestList(data[1]);
      })
      .catch(function (ex) {
        console.log("parsing failed", ex);
      });
  }, []);

  function updateText(evt) {
    // Do not allow single and double quotes
    let v = evt.target.value.replace(/['"]+/g, "");

    setinputText(v);
    fetchData(v);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      const domNode = reactDom.findDOMNode(this);
      if (!domNode || !domNode.contains(event.target)) {
        setSuggestList([]);
      }
    };
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [suggestList]);

  useEffect(() => {
    setinputText(queryString);
  }, [queryString]);

  // onKeyDown
  function getKey(evt) {
    let kCode = evt.keyCode;

    // Let's exit if not these
    if (kCode !== 40 && kCode !== 38 && kCode !== 27 && kCode !== 13) {
      return;
    }

    let parent = document.getElementById("ac-results");
    if (!parent) {
      return;
    }

    let activ = parent.getElementsByClassName("activ-element");
    let children = parent.getElementsByClassName("g-suggest");

    // Arrow Down
    if (kCode === 40) {
      arrowDown();
      return;
    }

    // Arrow Up
    if (kCode === 38) {
      arrowUp();
      return;
    }

    // Esc
    if (kCode === 27) {
      destroy();
      return;
    }

    //  Enter Key
    if (kCode === 13) {
      enter(evt);
      return;
    }

    // Enter key
    function enter(evt) {
      if (activ.length > 0) {
        evt.preventDefault();
        setinputText(activ[0].innerHTML);
        destroy();
      }
    }

    // Arrow Dwown
    function arrowDown() {
      if (activ.length === 0) {
        children[0].classList.add("activ-element", "bg-slate-200");
        document.getElementById("keyword-textbox").value =
          children[0].innerText;
      } else {
        // Get the index of activ element
        let idx = activ[0].getAttribute("index");
        idx++;

        // Clean
        cleanSelection();

        // Let's check index is not equal to max
        if (idx > children.length - 1) {
          idx = 0;
        }

        children[idx].classList.add("activ-element", "bg-slate-200");
        // document.getElementById("keyword-textbox").value = children[idx].innerText;
      }
    }

    // Arrow Dwon
    function arrowUp() {
      if (activ.length > 0) {
        // Get the index of activ element
        let idx = activ[0].getAttribute("index");
        idx--;

        // Clean
        cleanSelection();

        // Let's check index is not equal to max
        if (idx === -1) {
          idx = children.length - 1;
        }

        children[idx].classList.add("activ-element", "bg-slate-200");
        // document.getElementById("keyword-textbox").value = children[idx].innerText;
      }
    }

    function destroy() {
      setSuggestList([]);
    }

    function cleanSelection() {
      for (let i = 0; i < children.length; i++) {
        children[i].classList.remove("activ-element", "bg-slate-200");
      }
    }
  }

  return (
    <div className="sf-1 grow relative">
      <BsSearch className="absolute top-5 left-5 text-gray-400" />
      <input
        className="focus:outline-none focus:bg-slate-50 bg-white pl-12 pr-4 py-4 text-black w-full "
        type="text"
        name="keyword"
        id="keyword-textbox"
        placeholder="Enter a keyword ..."
        autoComplete="off"
        value={inputText}
        onChange={updateText}
        onKeyDown={getKey}
      ></input>

      <GoogleSuggest list={suggestList} textInput={setinputText} />
    </div>
  );
};

export default TextInput;
