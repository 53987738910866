import { useState, useEffect, useCallback } from "react";

import { fetchHeaders, MiniLoader, nFormatter } from "../Utils/Common";
import { API_BASE_URL } from "../../Constants/Contants";

export const DomainRankOverview = ({ domain }) => {
  let [isLoaded, setIsLoaded] = useState(false);
  let [content, setContent] = useState(false);

  const getDRO = useCallback(() => {
    fetch(
      API_BASE_URL + "/analyze/google-domain-rank-overview?domain=" + domain,
      fetchHeaders()
    )
      .then((response) => response.json())
      .then((data) => {
        setContent(data);
        setIsLoaded(true);
        console.log("Domain Rank Overview Fetched");
      });
  }, [domain]);

  useEffect(() => {
    getDRO();
  }, [getDRO]);

  const DomainOverview = () => {
    if (content.result.status_code !== 200) {
      return <div className="dro">-</div>;
    } else {
      let etv = content.result.response.metrics.organic.etv;
      etv = isNaN(etv) ? "-" : nFormatter(Number(etv), 2);

      let count = content.result.response.metrics.organic.count;
      count = isNaN(count) ? "-" : nFormatter(Number(count), 2);

      return (
        <>
          <div className="serp-3">{count}</div>
          <div className="serp-4">{etv}</div>
        </>
      );
    }
  };

  if (isLoaded) {
    return <DomainOverview />;
  } else {
    return (
      <>
        <div className="serp-3">
          <MiniLoader />
        </div>
        <div className="serp-4">
          <MiniLoader />
        </div>
      </>
    );
  }
};
