import { useNavigate } from "react-router-dom";
import { useContext } from "react";

import { AppContext } from "../../../Contexts/AppContexts";

import { CommonError, fetchHeaders, SectionHeader } from "../../Utils/Common";
import { BsQuestion, BsCollection, BsBarChartLine } from "react-icons/bs";

import { API_BASE_URL } from "../../../Constants/Contants";

import {
  MdControlCamera,
  MdOutlineQuestionAnswer,
  MdBarChart,
} from "react-icons/md";

const SavedNiche = () => {
  const { setMode, setDoSearch, setQueryString, setAccountInfo } =
    useContext(AppContext);

  const nav = useNavigate();
  const doAction = (action, string) => {
    setMode(action);
    setDoSearch(true);
    setQueryString(string);
    nav("/");
  };

  const deleteNiche = (index, keyword) => {
    document.querySelector(`[del-key="${index}"]`).remove();

    // Let's save to database
    fetch(API_BASE_URL + "/favorites/delete?q=" + keyword, fetchHeaders())
      .then((response) => response.json())
      .then((data) => {
        // Let's update our local storage with new info
        localStorage.setItem("user_info", JSON.stringify(data.result));

        setAccountInfo(data.result);
      });
  };

  let accInfo = JSON.parse(localStorage.getItem("user_info"));

  if (accInfo) {
    let listItems;

    if (accInfo.details.saved_niche.length) {
      listItems = accInfo.details.saved_niche.map((x, index) => {
        return (
          <li key={index} className="sn-entry" sn-index={index}>
            <BsQuestion
              onClick={() => doAction("ask", x)}
              title="Generate Questions"
              className="sn-action"
            />
            <BsCollection
              onClick={() => doAction("expand", x)}
              title="Generate Keywords"
              className="sn-action"
            />

            <BsBarChartLine
              onClick={() => doAction("analyze", x)}
              title="Analyze Keyword"
              className="sn-action"
            />

            <div className="sn-keyword">{x}</div>
            <div
              className="sn-delete"
              del-key={index}
              onClick={() => deleteNiche(index, x)}>
              delete
            </div>
          </li>
        );
      });

      listItems = <ul id="sn-entries">{listItems}</ul>;
    } else {
      listItems = <CommonError message="Nothing in here ..." />;
    }

    return (
      <div id="saved-niche">
        <SectionHeader title="Favorite Niche" />
        {listItems}
      </div>
    );
  } else {
    return <></>;
  }
};

export default SavedNiche;
