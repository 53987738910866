import React, { useState, useEffect, useContext } from "react";

import { BsQuestion, BsCollection, BsBarChartLine } from "react-icons/bs";
import { MdOutlineUnfoldMore } from "react-icons/md";

import { ResponsiveContainer, BarChart, Bar } from "recharts";
import {
  BasicLoader,
  CommonError,
  SectionHeader,
  ZeroResults,
  keyGen,
  fetchHeaders,
  difficultyColor,
} from "../Utils/Common";

import DownloadButton from "../Utils/DownloadButton";
import { API_BASE_URL } from "../../Constants/Contants";
import { AppContext } from "../../Contexts/AppContexts";
import { ExpandChart } from "./ExpandChart";
import { KeywordEntry } from "./KeywordEntry";
import { KeywordsContext } from "../../Contexts/KeywordsContext";

export const Ideas = () => {
  const {
    queryString,
    expandMode,
    setMode,
    setDoSearch,
    setQueryString,
    country,
  } = useContext(AppContext);

  let [isLoaded, setIsLoaded] = useState(false);
  let [activeKeyword, setActiveKeyword] = useState("");

  let [sortAsc, setSortAsc] = useState(false);
  let [chartData, setChartData] = useState("");
  let [content, setContent] = useState("");

  const getIdeas = () => {
    // Looks like this is a new query
    fetch(
      API_BASE_URL +
        "/expand/" +
        expandMode +
        "?q=" +
        queryString +
        "&location_code=" +
        country.location_code,
      fetchHeaders()
    )
      .then((response) => response.json())
      .then((data) => {
        setContent(data);
        setIsLoaded(true);

        console.log(expandMode + " Fetched!");
      });
  };

  useEffect(() => {
    setIsLoaded(false);
    getIdeas();
  }, [queryString]);

  useEffect(() => {}, [content]);

  //  The main function
  function getKeywordList() {
    let listItems;
    let firstElement = "";

    if (content.result.status_code !== 200) {
      // There is some issue
      if (content.result.status_code === 600) {
        listItems = <ZeroResults />;
      } else {
        listItems = <CommonError message={content.result.status_message} />;
      }
    } else {
      let kw, sv, cpc, ds, d;

      listItems = content.result.response.map((item) => {
        //  LOOP
        if (expandMode === "related") {
          kw = item.keyword_data.keyword;
          sv = item.keyword_data.keyword_info.search_volume;
          cpc = item.keyword_data.keyword_info.cpc;
          ds = item.keyword_data.keyword_info.monthly_searches;
          d =
            item.keyword_data.keyword_properties.keyword_difficulty !== null
              ? item.keyword_data.keyword_properties.keyword_difficulty
              : "--";

          if (
            item.keyword_data.keyword.toLowerCase() ===
            queryString.toLowerCase()
          ) {
            firstElement = {
              keyword: kw,
              sv: sv,
              cpc: cpc,
              difficulty: d,
              dataSet: ds,
            };
          }
        } else {
          kw = item.keyword;
          sv = item.keyword_info.search_volume;
          cpc = item.keyword_info.cpc;
          ds = item.keyword_info.monthly_searches;
          d =
            item.keyword_properties.keyword_difficulty !== null
              ? item.keyword_properties.keyword_difficulty
              : "--";

          // Get the actual keyword data
          if (item.keyword.toLowerCase() === queryString.toLowerCase()) {
            firstElement = {
              keyword: kw,
              sv: sv,
              cpc: cpc,
              difficulty: d,
              dataSet: ds,
            };
          }
        }

        let p = {
          keyword: kw,
          sv: sv,
          cpc: cpc,
          difficulty: d,
          dataSet: ds,
        };
        return (
          <div
            className="ks-entry"
            onClick={(e) => {
              if (
                e.target.className === "k-b" ||
                e.target.className === "ks-entry"
              ) {
                setChartData(p);
              }
            }}
          >
            {/* <KeywordEntry
              keyword={kw}
              sv={sv}
              cpc={cpc}
              difficulty={d}
              dataSet={ds}
            /> */}
            {/* <KeywordEntry data={p} content={content} setContent={setContent} /> */}
            <KeywordEntry data={p} />
          </div>
        );
      });
    }

    function Entries() {
      return <>{listItems}</>;
    }

    let data = chartData ? chartData : firstElement;

    let header_title = "";
    if (expandMode === "related") {
      header_title = `Keywords Related to `;
    }

    if (expandMode === "ideas") {
      header_title = `Keyword Ideas for `;
    }

    if (expandMode === "suggest") {
      header_title = `Keyword Suggestions for `;
    }

    return (
      <>
        <KeywordsContext.Provider value={{ content, setContent }}>
          <div id="expand-chart" className="mb-6">
            <h1 className="result-header">
              Keyword Overview: <span>{data.keyword}</span>
            </h1>

            <ExpandChart data={data} sourceContent={content} />
          </div>

          <div id="expand-results">
            <SectionHeader title={header_title + queryString} />

            {content.result.status_code !== 200 ? (
              <Entries key={keyGen()} />
            ) : (
              <>
                <div className="ks-header">
                  <div
                    className="k-a"
                    sort-label="trend"
                    onClick={() => {
                      filterList();
                    }}
                  >
                    Trend
                  </div>
                  <div
                    className="k-b"
                    sort-label="bare-niche"
                    onClick={() => {
                      sortList("keyword");
                    }}
                  >
                    <MdOutlineUnfoldMore className="inline relative -top-0.5" />
                    Niche
                  </div>
                  <div
                    className="k-c"
                    sort-label="volume"
                    onClick={() => {
                      sortList("search_volume");
                    }}
                  >
                    <MdOutlineUnfoldMore className="inline relative -top-0.5" />
                    Volume
                  </div>
                  <div
                    className="k-d"
                    sort-label="cpc"
                    onClick={() => {
                      sortList("cpc");
                    }}
                  >
                    <MdOutlineUnfoldMore className="inline relative -top-0.5" />
                    CPC
                  </div>
                  <div
                    className="k-e"
                    sort-label="difficulty"
                    onClick={() => {
                      sortList("difficulty");
                    }}
                  >
                    <MdOutlineUnfoldMore className="inline relative -top-0.5" />
                    Difficulty
                  </div>
                </div>
                <div
                  id="ks-entries"
                  className="overflow-hidden overflow-y-scroll"
                  style={{ maxHeight: `400px`, minHeight: "300px" }}
                >
                  <Entries key={keyGen()} />
                </div>

                <DownloadButton wrapper="expand-wrap" />
              </>
            )}
          </div>
        </KeywordsContext.Provider>
      </>
    );
  }

  function entryClicked(p) {
    // If there is no difficulty, this is a bit tricky
    if (p.difficulty === "--") {
      p.difficulty = "...";
      // setChartData(p);

      // Let's copy the current content and set it to variable that we can edit
      let newEntry = content;

      // Find this entry the has missing keyword
      content.result.response.map((item, index) => {
        if (item.keyword_data.keyword === p.keyword) {
          newEntry.result.response[
            index
          ].keyword_data.keyword_properties.keyword_difficulty = "...";

          setContent(newEntry);

          fetch(
            API_BASE_URL +
              "/keyword-difficulty?q=" +
              p.keyword +
              "&location_code=" +
              country.location_code,
            fetchHeaders()
          )
            .then((response) => response.json())
            .then((data) => {
              newEntry.result.response[
                index
              ].keyword_data.keyword_properties.keyword_difficulty =
                data.result.difficulty;

              // p.difficulty = 99;
              // setChartData(p);
              setContent(newEntry);
            });

          // console.log(data.result.difficulty);
          console.log(newEntry);
        }
      });
    } else {
      setChartData(p);
    }
  }

  // The main return
  return isLoaded ? <>{getKeywordList()}</> : <BasicLoader />;

  function sortList(sortBy) {
    if (content.result.response) {
      content.result.response.sort((a, b) => {
        let order = sortAsc ? 1 : -1;

        if (expandMode === "ideas" || expandMode === "suggest") {
          if (sortBy === "keyword") {
            return a[sortBy].toLowerCase() < b[sortBy].toLowerCase()
              ? -1 * order
              : 1 * order;
          }

          if (sortBy === "difficulty") {
            return a["keyword_properties"]["keyword_difficulty"] <
              b["keyword_properties"]["keyword_difficulty"]
              ? -1 * order
              : 1 * order;
          }

          return a["keyword_info"][sortBy] < b["keyword_info"][sortBy]
            ? -1 * order
            : 1 * order;
        } else {
          // If Related item.keyword_data.keyword_properties.keyword_difficulty
          if (sortBy === "keyword") {
            return a["keyword_data"][sortBy].toLowerCase() <
              b["keyword_data"][sortBy].toLowerCase()
              ? -1 * order
              : 1 * order;
          }

          if (sortBy === "difficulty") {
            return a["keyword_data"]["keyword_properties"][
              "keyword_difficulty"
            ] < b["keyword_data"]["keyword_properties"]["keyword_difficulty"]
              ? -1 * order
              : 1 * order;
          }

          return a["keyword_data"]["keyword_info"][sortBy] <
            b["keyword_data"]["keyword_info"][sortBy]
            ? -1 * order
            : 1 * order;
        }
      });

      setSortAsc(!sortAsc);
    }
  }

  function filterList() {
    // console.log(relatedContent);
    // const newList = relatedContent.result.response.filter((entry) => {
    //   return entry.keyword_data.keyword.includes("mini");
    // });
  }
};
