import { Navigate, Outlet } from "react-router-dom";

import Footer from "./Footer";
import Header from "./Header";

const ProtectedRoutes = ({ auth }) => {
    if (auth) {
        return (
            <>
                <Header />
                <div className='content bg-white px-5'>
                    <div className='max-w-7xl mx-auto'>
                        <Outlet />

                        <Footer />
                    </div>
                </div>
            </>
        );
    } else {
        return <Navigate to='/login' />;
    }
};
export default ProtectedRoutes;
