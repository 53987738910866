const GoogleSuggest = ({ list, textInput }) => {
  if (!list || list.length === 0) {
    return null;
  }

  return (
    <div
      id="ac-results"
      className="bg-slate-50 rounded-b-lg shadow-lg pb-2 absolute w-full z-20"
    >
      {list.map((keyword, index) => {
        return (
          <Entry
            key={index}
            index={index}
            keyword={keyword}
            textInput={textInput}
          />
        );
      })}
    </div>
  );
};
export default GoogleSuggest;

const Entry = ({ keyword, index, textInput }) => {
  function selectElement(evt) {
    let curElem = document.querySelector(".g-suggest.activ-element");

    if (curElem) {
      curElem.classList.remove("bg-slate-200", "activ-element");
    }

    evt.target.classList.add("bg-slate-200", "activ-element");

    // document.getElementById("keyword-textbox").value = evt.target.textContent;
  }

  function setKeyword(evt) {
    textInput(evt.target.textContent);
  }

  return (
    <div
      className="g-suggest px-5 py-2 text-slate-500 cursor-pointer hover:bg-slate-200"
      index={index}
      onMouseOver={selectElement}
      onMouseDown={setKeyword}
    >
      {keyword}
    </div>
  );
};
