import { useContext } from "react";
import { AppContext } from "../../Contexts/AppContexts";

import { Quora } from "./Quora";
import { GoogleSuggest } from "./GoogleSuggest";
import { BasicPrompt } from "../Utils/Common";

import DownloadButton from "../Utils/DownloadButton";
// import base64 from "base64topdf";

const Ask = () => {
  const { mode, queryString, doSearch } = useContext(AppContext);

  if (queryString && doSearch) {
    return (
      <>
        <div id="ask-wrap">
          <div id="ask" className={mode === "ask" ? "block" : "hidden"}>
            <h1 className="result-header">
              Questions Related to <span>{queryString}</span>
            </h1>
            <Quora />
            <GoogleSuggest q={queryString} />
          </div>
        </div>
        <DownloadButton wrapper="ask-wrap" />
      </>
    );
  } else {
    return (
      <div id="ask" className={mode === "ask" ? "block" : "hidden"}>
        <BasicPrompt />
      </div>
    );
  }
};

export default Ask;
