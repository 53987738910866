import { ItunesApps } from "./iTunes/AppStore";
import { ItunesPodcast } from "./iTunes/Podcast";

export const Itunes = ({ q }) => {
  return (
    <div id="itunes">
      <div className="it-podcast">
        <ItunesPodcast key={"an-itp-" + q.replace(/\s/g, "")} q={q} />
      </div>
      <div className="it-apps">
        <ItunesApps key={"an-ita-" + q.replace(/\s/g, "")} q={q} />
      </div>
    </div>
  );
};
