export const APP_VERSION = "1.2.1";

// LOCALHOST
// export const APP_DATA_URL = "http://ideabotdata.test/";
// export const API_BASE_URL = "http://ideabotdata.test/wp-json/ideabot/v1";

// STAGING
// export const APP_DATA_URL = "https://stagingdata.ideabot.ai";
// export const API_BASE_URL = "https://stagingdata.ideabot.ai/wp-json/ideabot/v1";

// LIVE
export const APP_DATA_URL = "https://data.ideabot.ai";
export const API_BASE_URL = "https://data.ideabot.ai/wp-json/ideabot/v1";

// CDN https://AppIdeabot.b-cdn.net
// basic@ideabot.ai : BasicUser1
// pro@ideabot.ai : ProUser1

export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
