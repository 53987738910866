import { useState, useEffect, useCallback, useContext } from "react";
import RelativeTime from "@yaireo/relative-time";

import { BasicLoader, SectionHeader, ZeroResults, fetchHeaders } from "../../Utils/Common";
import { MdOutlineNorthEast } from "react-icons/md";

export const ItunesPodcast = ({ q }) => {
  let [isLoaded, setIsLoaded] = useState(false);
  let [content, setContent] = useState(false);

  const relativeTime = new RelativeTime();

  const getItunesPodcasts = useCallback(() => {
    fetch("https://itunes.apple.com/search?media=podcast&limit=5&term=" + q, fetchHeaders())
      .then((response) => response.json())
      .then((data) => {
        setContent(data);
        setIsLoaded(true);
        console.log("iTunes Apps Fetched");
      });
  }, [q]);

  useEffect(() => {
    getItunesPodcasts();
  }, [q, getItunesPodcasts]);

  function Podcast({ data }) {
    return (
      <div className="it-entry">
        <div className="it-a">
          <img src={data.artworkUrl100} alt={data.collectionName} />
        </div>
        <div className="it-b">
          <h3 className="it-b-1">
            <a href={data.collectionViewUrl} target="_blank" rel="noreferrer">
              <span className="mr-2">{data.collectionName}</span>
              <MdOutlineNorthEast className="ext-link" />
            </a>
          </h3>
          <div className="it-b-2">
            {" by "}
            <span>{data.artistName.substring(0, 60)}</span>
          </div>
          <div className="it-b-3">
            {data.trackCount} tracks, {relativeTime.from(new Date(data.releaseDate))}
          </div>
        </div>
      </div>
    );
  }

  function displayItunesPodcasts() {
    let listItems;

    // Check if we got results
    if (content.resultCount === 0) {
      listItems = <ZeroResults />;
    } else {
      listItems = content.results.map((x) => {
        return <Podcast key={x.trackId} data={x} />;
      });
    }

    return (
      <>
        <SectionHeader title="iTunes Podcasts" ToolTipTitle="iTunes Podcasts" ToolTipPointer="itunes_podcasts" />
        <div className="it-entries">{listItems}</div>
      </>
    );
  }

  if (isLoaded) {
    return <>{displayItunesPodcasts()}</>;
  } else {
    return (
      <>
        <SectionHeader title="iTunes Podcasts" ToolTipTitle="iTunes Podcasts" ToolTipPointer="itunes_podcasts" />
        <BasicLoader />
      </>
    );
  }
};
