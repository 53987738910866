import { useState, useEffect, useLayoutEffect } from "react";

import { PageTitle } from "./PageTitle";
import { BasicLoader } from "../Utils/Common";

const Training = () => {
  let [vid, setVid] = useState("718506647?h=qBNs3krn1PM");
  let [lesson, setLesson] = useState(1);

  useLayoutEffect(() => {
    YoutubeEmbed(vid);
  });

  const YoutubeEmbed = ({ embedId, vidTitle }) => (
    <div className="video-responsive">
      <iframe
        title={vidTitle}
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        src={`https://player.vimeo.com/video/${embedId}`}
      ></iframe>
    </div>
  );

  useEffect(() => {
    YoutubeEmbed(vid);
  }, [vid]);

  return (
    <div>
      <PageTitle title="Training" />

      <YoutubeEmbed embedId={vid} />

      <div id="t-nav">
        <div
          className={lesson === 1 ? "active" : "inactive"}
          onClick={() => {
            setVid("718506647?h=qBNs3krn1PM", "Introduction To IdeaBot.ai");
            setLesson(1);
          }}
        >
          <span className="t-a">Lesson 1</span>
          <span className="t-b">Introduction To IdeaBot.ai</span>
        </div>
        <div
          className={lesson === 2 ? "active" : "inactive"}
          onClick={() => {
            setVid("718506626?h=2fe9c84e53", "Keyword Research");
            setLesson(2);
          }}
        >
          <span className="t-a">Lesson 2</span>
          <span className="t-b">Keyword Research</span>
        </div>
        <div
          className={lesson === 3 ? "active" : "inactive"}
          onClick={() => {
            setVid("718506648?h=56a1100d42", "Questions Research");
            setLesson(3);
          }}
        >
          <span className="t-a">Lesson 3</span>
          <span className="t-b">Questions Research</span>
        </div>
        <div
          className={lesson === 4 ? "active" : "inactive"}
          onClick={() => {
            setVid("718506664?h=7743893944", "Deep Dive Niche Analysis");
            setLesson(4);
          }}
        >
          <span className="t-a">Lesson 4</span>
          <span className="t-b">Deep Dive Niche Analysis</span>
        </div>
        <div
          className={lesson === 5 ? "active" : "inactive"}
          onClick={() => {
            setVid("718506705?h=ddce94f8db", "Discovering Ideas");
            setLesson(5);
          }}
        >
          <span className="t-a">Lesson 5</span>
          <span className="t-b">Discovering Ideas</span>
        </div>
      </div>
    </div>
  );
};

export default Training;
