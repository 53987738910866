import { useContext } from "react";

import { DiscoverContext } from "../../Contexts/DiscoverContexts";
import { CommonError, SectionHeader, ZeroResults } from "../Utils/Common";

const NicheSubCategory = ({ data }) => {
  const {
    subCategoryData,
    setDisplayMode,
    setNichesCategoryId,
    setIsLoaded,
    setSubCategoryData,
  } = useContext(DiscoverContext);

  function displaySubCategory() {
    let listItems;

    if (subCategoryData.status_code !== 200) {
      if (subCategoryData.status_code === 600) {
        listItems = <ZeroResults />;
      } else {
        console.log(subCategoryData);
        listItems = <CommonError message={subCategoryData.status_message} />;
      }
    } else {
      listItems = subCategoryData.sub_category.map((x) => {
        return (
          <div
            onClick={() => {
              setDisplayMode("niches");
              setIsLoaded(false);
              setNichesCategoryId(x.id);
            }}
            key={x.id}
            className="niche-item niche-item-cat">
            {x.name.replace(/&amp;/g, "&")}
            <span className="niche-count">{x.item_count}</span>
          </div>
        );
      });
    }
    return (
      <>
        <h1 className="result-header">
          Explore{" "}
          <span>{subCategoryData.category_name.replace(/&amp;/g, "&")}</span>{" "}
          Directory
        </h1>

        <div
          className="dn"
          onClick={() => {
            setDisplayMode("main-category");
            setSubCategoryData("");
            setIsLoaded(false);
          }}>
          Back To Main Directory
        </div>

        <SectionHeader
          title={subCategoryData.category_name.replace(/&amp;/g, "&")}
        />

        <div className="niche-list">{listItems}</div>
      </>
    );
  }

  // The main return
  return <>{displaySubCategory()}</>;
};

export default NicheSubCategory;
