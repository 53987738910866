import { useState, useEffect, useCallback, useContext } from "react";

import { MdOutlineNorthEast, MdContentCopy } from "react-icons/md";

import { AnalyzeContext } from "../../Contexts/AnalyzeContexts";
import { AppContext } from "../../Contexts/AppContexts";

import {
  BasicLoader,
  CommonError,
  keyGen,
  SectionHeader,
  ZeroResults,
  fetchHeaders,
  ToolTipHelper,
  nFormatter,
} from "../Utils/Common";
import { API_BASE_URL } from "../../Constants/Contants";
import { DomainRankOverview } from "./DomainRankOverview";

export const KeywordSerp = ({ q }) => {
  let [isLoaded, setIsLoaded] = useState(false);
  let [content, setContent] = useState(false);

  let { country } = useContext(AppContext);

  const getSerp = useCallback(() => {
    fetch(API_BASE_URL + "/analyze/serp?q=" + q + "&location_code=" + country.location_code, fetchHeaders())
      .then((response) => response.json())
      .then((data) => {
        setContent(data.result);
        setIsLoaded(true);
        console.log("Keyword Serp fetched");
        console.log(data.result);
      });
  }, [q, country.location_code]);

  useEffect(() => {
    getSerp();
  }, [q, getSerp]);

  function Domain({ info }) {
    return (
      <div className="serp-b">
        <div className="serp-1 text-center">{info.rank_group}</div>
        <div className="serp-2">
          <a href={info.url}>
            <div className="serp-bread">{info.breadcrumb}</div>
            <div className="serp-title">{info.title}</div>
            <MdOutlineNorthEast className="inline relative -top-0.5 ml-3 text-orange-400" />
          </a>
        </div>
        <DomainRankOverview domain={info.domain} />
        {/* <div className="serp-5">{nFormatter(Number(info.domain_backlink))}</div>
        <div className="serp-6">{info.domain_rank}</div> */}
      </div>
    );
  }

  function copyToClipboard() {
    let parent = document.querySelector("#serp");

    let children = parent.querySelectorAll(".serp-b");
    let i;

    let csv;
    let stringCSV = "";

    for (i = 0; i < children.length; ++i) {
      csv = children[i].innerText;
      let scvArray = csv.split("\n");

      // stringCSV =
      //   stringCSV + scvArray[1] + ", " + scvArray[3] + ", " + scvArray[4] + ", " + scvArray[5] + ", " + scvArray[6] + "\n";

      stringCSV = stringCSV + scvArray[1] + ", " + scvArray[3] + ", " + scvArray[4] + "\n";
    }

    // Send this to clipboard
    navigator.clipboard.writeText(stringCSV);

    document.querySelector(".copy-text").innerText = "Copied!";

    // Set the text back
    setInterval(() => {
      document.querySelector(".copy-text").innerText = "Copy To Clipboard";
    }, 1500);
  }

  function displaySerp() {
    let listItems;

    // Check if there is no error
    if (content.status_code !== 200) {
      if (content.status_code === 600) {
        listItems = <ZeroResults />;
      } else {
        listItems = <CommonError message={content.status_message} />;
      }
    } else {
      listItems = content.response.map((item) => {
        return <Domain key={keyGen()} info={item} />;
      });
    }

    return (
      <>
        <div id="serp">
          <SectionHeader
            title="Google Competitor Analysis"
            ToolTipTitle="Competitor Analysis"
            ToolTipPointer="google_competitor_analysis"
          />
          <div className="serp-a">
            <div className="serp-1">#</div>
            <div className="serp-2">Domain</div>
            <div className="serp-3">
              Ranked <br />
              Keywords
              <ToolTipHelper title="Ranked Keywords" pointer="domain_ranked_keywords" />
            </div>
            <div className="serp-4">
              Estimated <br />
              Traffic
              <ToolTipHelper title="Estimated Traffic" pointer="domain_traffic" />
            </div>
            {/* <div className="serp-5">
              Domain <br />
              Backlinks
              <ToolTipHelper title="Estimated Backlinks" pointer="domain_backlinks" />
            </div>
            <div className="serp-6">
              Domain <br />
              Page Rank
              <ToolTipHelper title="Domain Rank" pointer="domain_rank" />
            </div> */}
          </div>

          {listItems}
          <div className="copy-text-wrap" onClick={() => copyToClipboard()}>
            <MdContentCopy /> <span className="copy-text">Copy To Clipboard</span>
          </div>
        </div>
      </>
    );
  }
  return isLoaded ? (
    <>{displaySerp()}</>
  ) : (
    <>
      <SectionHeader
        title="Google Competitor Analysis"
        ToolTipTitle="Competitor Analysis"
        ToolTipPointer="google_competitor_analysis"
      />
      <BasicLoader />
    </>
  );
};
