import { useContext } from "react";
import { AppContext } from "../../Contexts/AppContexts";
import { BasicPrompt } from "../Utils/Common";
import { Ideas } from "./Ideas";

const Expand = () => {
  const { queryString, doSearch } = useContext(AppContext);

  if (queryString && doSearch) {
    return (
      <div id="expand-wrap">
        <div id="expand">
          <Ideas key={queryString} />
        </div>
      </div>
    );
  } else {
    return (
      <div id="expand">
        <BasicPrompt />
      </div>
    );
  }
};

export default Expand;
