import { useState, useEffect, useCallback, useContext } from "react";

import { ResponsiveContainer, BarChart, Bar } from "recharts";

import { AnalyzeContext } from "../../Contexts/AnalyzeContexts";
import { AppContext } from "../../Contexts/AppContexts";
import {
  BasicLoader,
  CommonError,
  keyGen,
  SectionHeader,
  ZeroResults,
  fetchHeaders,
} from "../Utils/Common";

import { BsQuestion, BsCollection } from "react-icons/bs";

import { MdOutlineUnfoldMore } from "react-icons/md";

import { API_BASE_URL } from "../../Constants/Contants";

export const KeywordIdeas = () => {
  let [isLoaded, setIsLoaded] = useState(false);
  let [content, setContent] = useState(false);
  let [sortAsc, setSortAsc] = useState(false);

  let { setNicheDrillDown } = useContext(AnalyzeContext);

  const { setMode, setDoSearch, setQueryString, country, queryString } =
    useContext(AppContext);

  const getIdeas = useCallback(() => {
    setIsLoaded(false);
    fetch(
      API_BASE_URL +
        "/expand/related?q=" +
        queryString +
        "&location_code=" +
        country.location_code,
      fetchHeaders()
    )
      .then((response) => response.json())
      .then((data) => {
        setContent(data.result);
        setNicheDrillDown(data.result);
        setIsLoaded(true);
        console.log("Keyword Related fetched");
      });
  }, [queryString]);

  useEffect(() => {
    getIdeas();
  }, [queryString, getIdeas]);

  function Idea(props) {
    let iconCss = "inline mr-2 text-orange-500 hover:text-black";

    const doAction = (action, string) => {
      setMode(action);
      setDoSearch(true);
      setQueryString(string);
    };

    return (
      <div className="ndd-entry border-b border-slate-200 cursor-pointer hover:bg-slate-100 text-sm items-center">
        <div className="ndd-a">
          <ResponsiveContainer>
            <BarChart data={props.dataSet.slice(0).reverse()}>
              <Bar
                dataKey="search_volume"
                fill="#CBD5E1"
                isAnimationActive={false}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="ndd-b">
          <BsQuestion
            className={iconCss}
            onClick={() => doAction("ask", props.keyword)}
            title="Generate Questions"
          />
          <BsCollection
            className={iconCss}
            onClick={() => doAction("expand", props.keyword)}
            title="Generate Keywords"
          />
          <div>{props.keyword}</div>
        </div>
        <div className="ndd-c ">{Number(props.sv).toLocaleString(2)}</div>
        <div className="ndd-d ">{Number(props.cpc).toFixed(2)}</div>
      </div>
    );
  }

  function sortList(sortBy) {
    content.response.sort((a, b) => {
      let order = sortAsc ? 1 : -1;

      if (sortBy === "keyword") {
        return a["keyword_data"][sortBy].toLowerCase() <
          b["keyword_data"][sortBy].toLowerCase()
          ? -1 * order
          : 1 * order;
      } else {
        return a["keyword_data"]["keyword_info"][sortBy] <
          b["keyword_data"]["keyword_info"][sortBy]
          ? -1 * order
          : 1 * order;
      }
    });

    setSortAsc(!sortAsc);
  }

  function displayIdeas() {
    let listItems;

    // Check if there is no error
    if (content.status_code !== 200) {
      if (content.status_code === 600) {
        listItems = <ZeroResults />;
      } else {
        listItems = <CommonError message={content.status_message} />;
      }
    } else {
      listItems = content.response.map((item) => {
        return (
          <>
            <Idea
              key={keyGen()}
              keyword={item.keyword_data.keyword}
              sv={item.keyword_data.keyword_info.search_volume}
              cpc={item.keyword_data.keyword_info.cpc}
              dataSet={item.keyword_data.keyword_info.monthly_searches}
            />
          </>
        );
      });
    }

    return (
      <>
        <SectionHeader
          title="Niche Drill Down"
          ToolTipTitle="Niche Drill Down"
          ToolTipPointer="niche_drill_down"
        />
        <div className="ndd-header font-bold text-orange-600 uppercase mb-2">
          <div className="ndd-e text-xs pl-2">Trend</div>
          <div
            className="ndd-f cursor-pointer hover:text-orange-600 w-14 text-xs"
            sort-label="bare-niche"
            onClick={() => {
              sortList("keyword");
            }}
          >
            <MdOutlineUnfoldMore className="inline relative -top-0.5" />
            Niche
          </div>
          <div
            className="ndd-g text-xs cursor-pointer hover:text-orange-600"
            sort-label="volume"
            onClick={() => {
              sortList("search_volume");
            }}
          >
            <MdOutlineUnfoldMore className="inline relative -top-0.5" />
            Volume
          </div>
          <div
            className="ndd-h text-xs cursor-pointer hover:text-orange-600"
            sort-label="volume"
            onClick={() => {
              sortList("cpc");
            }}
          >
            <MdOutlineUnfoldMore className="inline relative -top-0.5" />
            CPC
          </div>
        </div>

        <div id="ndd-entries" className="overflow-hidden overflow-y-scroll">
          {listItems}
        </div>
      </>
    );
  }
  return isLoaded ? (
    <>{displayIdeas()}</>
  ) : (
    <>
      <SectionHeader
        title="Niche Drill Down"
        ToolTipTitle="Niche Drill Down"
        ToolTipPointer="niche_drill_down"
      />
      <BasicLoader />
    </>
  );
};
