import { useState } from "react";

import { GoDaddy } from "./GoDaddy";
import { YouTube } from "./YouTube";
import { Quora } from "../Ask/Quora";
import { Twitter } from "./Twitter";
import { Reddit } from "./Reddit";
import { Itunes } from "./Itunes";
import { Merchant } from "./Merchant";
import { Ebay } from "./eBay";
import { KeywordSerp } from "./KeywordSerp";
import { Facebook } from "./Facebook";
import { AliExpress } from "./AliExpress";
import DownloadButton from "../Utils/DownloadButton";

export const FullReport = ({ q }) => {
  let [showReport, setShowReport] = useState(false);

  if (!showReport) {
    return (
      <div
        onClick={() => {
          setShowReport(true);
          document.getElementById("analyze-nav").removeAttribute("style");
        }}
        className="fr-btn"
      >
        Load Comprehensive Analysis
      </div>
    );
  } else {
    return (
      <>
        <KeywordSerp q={q} />
        <YouTube q={q} />
        <Quora key="an-qu" q={q} />

        {/* <Twitter key="an-tw" q={q} /> */}

        <Reddit key="an-re" q={q} />
        <Facebook />
        <Itunes q={q} />
        <Merchant q={q} />
        <Ebay q={q} />

        {/* <AliExpress q={q} /> */}

        <GoDaddy q={q} />

        <DownloadButton wrapper="analyze-wrap" />
      </>
    );
  }
};
