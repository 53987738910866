import { useContext } from "react";
import { AppContext } from "../../../Contexts/AppContexts";

const UserProfile = () => {
  const { accountInfo } = useContext(AppContext);

  let sty = {
    width:
      (accountInfo.details.recurring_credit.balance /
        accountInfo.details.membership_defaults.credits) *
        100 +
      "%",
  };

  const firstDate = new Date(accountInfo.details.recurring_credit.expiry);
  const daysRemain = Math.round(
    Math.abs((Number(firstDate) - Date.now()) / 86400000)
  );

  let sty2 = {
    width:
      (daysRemain / accountInfo.details.membership_defaults.validity) * 100 +
      "%",
  };

  return (
    <div className="px-3">
      <div className="uppercase text-center mb-8 font-bold text-xl text-orange-600">
        <img
          className="block mx-auto rounded-full border-4 border-white shadow-lg mb-3"
          src={accountInfo.info.avatar}
          alt="user-profile"
        />
        {accountInfo.details.access_level} Member
      </div>

      <p>
        Available Credits :{" "}
        <span className="font-bold">
          {accountInfo.details.recurring_credit.balance}
        </span>
        {" / "}
        {accountInfo.details.membership_defaults.credits}
      </p>

      <div className="bg-slate-200 h-3 rounded-full overflow-hidden mb-8">
        <div style={sty} className="bg-green-400 block h-full"></div>
      </div>

      <p>
        Credits will reset in : <span className="font-bold">{daysRemain}</span>{" "}
        days
      </p>

      <div className="bg-slate-200 h-3 rounded-full overflow-hidden">
        <div style={sty2} className="bg-red-400 block h-full"></div>
      </div>
    </div>
  );
};

export default UserProfile;
