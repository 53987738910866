import { useEffect, useState, useContext } from "react";
import { BasicLoader, SectionHeader, keyGen } from "../Utils/Common";
import { MdOutlineSms } from "react-icons/md";
import fetchJsonp from "fetch-jsonp";
import { BsQuestion, BsCollection, BsBarChartLine } from "react-icons/bs";
import { AppContext } from "../../Contexts/AppContexts";

export const GoogleSuggest = ({ q }) => {
  let [isLoaded, setIsLoaded] = useState(false);
  let [content, setContent] = useState("ABC");

  const { setMode, setDoSearch, setQueryString } = useContext(AppContext);

  useEffect(() => {
    getSuggest();
  }, [q]);

  async function getSuggest() {
    function fetchSuggest(prep) {
      return new Promise((suggestLists) => {
        setTimeout(() => {
          fetchJsonp(
            "https://suggestqueries.google.com/complete/search?client=chrome&q=" +
              prep +
              " " +
              q
          )
            .then((response) => response.json())
            .then((data) => {
              suggestLists(data[1]);
            });
        }, 100);
      });
    }

    let preps = [
      "how",
      "when",
      "where",
      "why",
      "what",
      "who",
      "will",
      "which",
      "can",
      "are",
    ];

    let list = [];
    let temp = [];
    for (const prep of preps) {
      temp = {
        prep: prep,
        items: await fetchSuggest(prep),
      };

      if (temp.items.length > 0) {
        list.push(temp);
      }
    }

    if (list) {
      setContent(list);
      setIsLoaded(true);
    }
  }

  const doAction = (action, string) => {
    setMode(action);
    setDoSearch(true);
    setQueryString(string.replace(/&amp;/g, "&").replace(/&#038;/g, "&"));
  };

  function Entry({ list, title }) {
    let listItems = list.items.map((item, index) => {
      return (
        <div className="grq-entry">
          <BsQuestion
            onClick={() => doAction("ask", item)}
            title="Generate Questions"
          />
          <BsCollection
            onClick={() => doAction("expand", item)}
            title="Generate Keywords"
          />
          <BsBarChartLine
            onClick={() => doAction("analyze", item)}
            title="Analyze Keyword"
          />
          <div className="grq-item">{item}</div>
        </div>
      );
    });

    return (
      <div className="grq-entries">
        <div className="grq-header">
          <MdOutlineSms />
          {title}
        </div>
        {listItems}
      </div>
    );
  }

  function Entries() {
    let suggestList = content.map((entry) => {
      return <Entry key={keyGen()} list={entry} title={entry.prep} />;
    });

    return (
      <>
        <SectionHeader
          title="Google Related Questions"
          ToolTipTitle="Google Related Questions"
          ToolTipPointer="google_related_questions"
        />
        <div className="grq-wrap">{suggestList}</div>
      </>
    );
  }

  if (isLoaded) {
    return <Entries />;
  } else {
    return (
      <>
        <SectionHeader
          title="Google Related Questions"
          ToolTipTitle="Google Related Questions"
          ToolTipPointer="google_related_questions"
        />
        <BasicLoader />
      </>
    );
  }
};
