import { RedditPosts } from "./Reddit/RedditPosts";
import { SubReddit } from "./Reddit/SubReddit";

export const Reddit = ({ q }) => {
  return (
    <div id="reddit">
      <div className="red-1">
        <SubReddit key={"an-sr-" + q.replace(/\s/g, "")} q={q} />
      </div>
      <div className="red-2">
        <RedditPosts key={"an-rp-" + q.replace(/\s/g, "")} q={q} />
      </div>
    </div>
  );
};
