import { useContext } from "react";
import { AppContext } from "../../Contexts/AppContexts";

import { DiscoverContextProvider } from "../../Contexts/DiscoverContexts";

import SearchForm from "../Seachbar/SearchForm";
import Discover from "../Discover/Discover";
import Ask from "../Ask/Ask";
import Expand from "../Expand/Expand";
import Analyze from "../Analyze/Analyze";
import AskMeAnything from "../AskMeAnything/Ama";

import SaveToFavorites from "../Utils/SaveToFavorites";

function Home() {
  let { mode, queryString } = useContext(AppContext);

  return (
    <>
      <SearchForm />

      {mode !== "ama" ? <SaveToFavorites keyword={queryString} /> : null}

      <div id="results-root">
        {mode === "discover" ? (
          <DiscoverContextProvider>
            <Discover />
          </DiscoverContextProvider>
        ) : null}

        {mode === "ask" ? <Ask /> : null}

        {mode === "expand" ? <Expand /> : null}

        {mode === "ama" ? <AskMeAnything /> : null}

        {mode === "analyze" ? <Analyze /> : null}
      </div>
    </>
  );
}

export default Home;
