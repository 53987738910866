import { createContext, useState} from 'react'

export const DiscoverContext = createContext({});

export function DiscoverContextProvider( {children} ) {

    const [displayMode, setDisplayMode] = useState('main-category')
    const [ mainCategoryData, setMainCategoryData ] = useState()
    const [ subCategoryData, setSubCategoryData ] = useState('')
    const [ subCategoryID, setSubCategoryID ] = useState('')
    const [ nichesCategoryId, setNichesCategoryId ] = useState('')
    const [ nichesCategoryData, setNichesCategoryData ] = useState('')
    const [ isLoaded, setIsLoaded ] = useState(false)

    return (
        <>
            <DiscoverContext.Provider 
                value={{
                    displayMode,
                    mainCategoryData,
                    subCategoryData, 
                    subCategoryID,
                    nichesCategoryId,
                    nichesCategoryData,
                    isLoaded,
                    setDisplayMode,
                    setMainCategoryData,
                    setSubCategoryData, 
                    setSubCategoryID,
                    setNichesCategoryId,
                    setNichesCategoryData,
                    setIsLoaded,
                }}
            >
                {children}
            </DiscoverContext.Provider>
        </>
    )
}