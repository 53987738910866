import { MdControlCamera, MdLightbulb, MdOutlineAutoGraph, MdLogout, MdPerson, MdSpaceDashboard } from "react-icons/md";

import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../../Contexts/AppContexts";
import { API_BASE_URL } from "../../Constants/Contants";
import { fetchHeaders } from "../Utils/Common";

const SubNavigation = () => {
  const { setMode, setDoSearch, setQueryString, accountInfo, setAccountInfo } = useContext(AppContext);
  const nav = useNavigate();

  function hideMobileMenu() {
    let menuElem = document.getElementById("pages-nav");
    menuElem.classList.remove("show-menu");
  }

  function showSideMenu() {
    fetch(API_BASE_URL + "/user", fetchHeaders())
      .then((response) => response.json())
      .then((data) => {
        localStorage.setItem("user_info", JSON.stringify(data.result));

        setAccountInfo(data.result);
      });

    document.getElementById("acc-side-menu").classList.toggle("hide");
    document.getElementById("acc-side-menu").focus();
  }

  function goDashboard(e) {
    e.preventDefault();

    setMode("expand");
    setDoSearch(true);
    setQueryString("");
    nav("/");
  }

  function linkClass(name) {
    let n = window.location.pathname;

    if (n === name) {
      return "active";
    }

    return;
  }

  return (
    <div id="pages-nav">
      {accountInfo.details.access_level === "basic" ? (
        <div className={"register-nav"}>
          <a href="https://ideabot.ai/pro-order-form/" target="_blank" rel="noreferrer">
            <MdOutlineAutoGraph /> Upgrade
          </a>
        </div>
      ) : (
        ""
      )}
      <a
        href="#root"
        onClick={(e) => {
          goDashboard(e);
        }}
        className={linkClass("/")}
      >
        <MdSpaceDashboard /> Dashboard
      </a>

      <Link to="/account" className={linkClass("/account")} onClick={hideMobileMenu}>
        <MdPerson /> Account
      </Link>

      {/* <Link to="/plan" className={linkClass("/account")} onClick={hideMobileMenu}>
        <MdPerson /> Plan
      </Link> */}

      <Link to="/training" className={linkClass("/training")} onClick={hideMobileMenu}>
        <MdLightbulb /> Training
      </Link>

      <Link to="/support" className={linkClass("/support")} onClick={hideMobileMenu}>
        <MdControlCamera /> Support
      </Link>

      <Link to={"/logout"} className={linkClass("/logout")} onClick={hideMobileMenu}>
        {" "}
        <MdLogout />
        Logout{" "}
      </Link>

      <Link to={"#"} className="acc-icon" onClick={() => showSideMenu()}>
        <img src={accountInfo.info.avatar} alt="user_photo" className="inline w-10 rounded-full" />
        <div className="membership">{accountInfo.details.access_level}</div>
      </Link>
    </div>
  );
};

export default SubNavigation;
