import { SectionHeader } from "../Utils/Common";
import { Videos } from "./YouTube/Videos";

export const YouTube = ({ q }) => {
  return (
    <div id="youtube" className="">
      <div className="yt-a">
        <SectionHeader
          title={"Youtube Videos"}
          ToolTipTitle="Youtube Videos"
          ToolTipPointer="youtube_videos"
        />
        <Videos key={"an-yv-" + q.replace(/\s/g, "")} q={q} type="video" />
      </div>
      <div className="yt-b">
        <SectionHeader
          title={"Youtube Channel"}
          ToolTipTitle="Youtube Channel"
          ToolTipPointer="youtube_channel"
        />
        <Videos key={"an-yc-" + q.replace(/\s/g, "")} q={q} type="channel" />
      </div>
    </div>
  );
};
