import { useState, useEffect, useCallback, useContext } from "react";

import { BasicLoader, ZeroResults, SectionHeader } from "../../Utils/Common";
import { MdOutlineNorthEast } from "react-icons/md";

export const RedditPosts = ({ q }) => {
  let [isLoaded, setIsLoaded] = useState(false);
  let [content, setContent] = useState(false);

  const getRedditPosts = useCallback(() => {
    fetch("https://www.reddit.com/search.json?limit=5&q=" + q)
      .then((response) => response.json())
      .then((data) => {
        setContent(data);
        setIsLoaded(true);
        console.log("Reddit Posts Fetched");
      });
  }, [q]);

  useEffect(() => {
    getRedditPosts();
  }, [q, getRedditPosts]);

  function Posts({ x }) {
    let desc = x.data.selftext;
    return (
      <div className="red-entry">
        <h3 className="red-title">
          <a className="text-sm font-bold" href={`https://www.reddit.com/${x.data.url}`} target="_blank" rel="noreferrer">
            <span className="mr-2">{x.data.title}</span>
            <MdOutlineNorthEast className="ext-link" />
          </a>
        </h3>
        <div className="red-stat">
          <span className="">{Number(x.data.score).toLocaleString()}</span>
          {" upvotes "}
          <span className="">{Number(x.data.num_comments).toLocaleString()}</span>
          {" comments "}
          <span className="">{timeDifference(x.data.created_utc)}</span>
          {" by "}
          <span className="red-blue">{x.data.author}</span>
          {" by "}
          <span className="red-blue">{x.data.subreddit_name_prefixed}</span>
        </div>
        <div className="red-desc">{desc.substring(0, 150)} ...</div>
      </div>
    );
  }

  function displayRedditPosts() {
    let listItems;
    if (content.data.children.length) {
      listItems = content.data.children.map((x, index) => {
        return <Posts key={index} x={x} />;
      });
    } else {
      listItems = <ZeroResults />;
    }

    return (
      <>
        <SectionHeader title="Popular Reddit Post" ToolTipTitle="Popular Reddit Post" ToolTipPointer="reddit_post" />
        <div className="red-entries">{listItems}</div>
      </>
    );
  }

  if (isLoaded) {
    return <>{displayRedditPosts()}</>;
  } else {
    return (
      <>
        <SectionHeader title="Popular Reddit Post" ToolTipTitle="Popular Reddit Post" ToolTipPointer="reddit_post" />
        <BasicLoader />
      </>
    );
  }
};

function timeDifference(elapsed) {
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;

  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + " seconds ago";
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + " minutes ago";
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + " hours ago";
  } else if (elapsed < msPerMonth) {
    return Math.round(elapsed / msPerDay) + " days ago";
  } else if (elapsed < msPerYear) {
    return Math.round(elapsed / msPerMonth) + " months ago";
  } else {
    return Math.round(elapsed / msPerYear) + " years ago";
  }
}
