import { useContext } from "react";

import { DiscoverContext } from "../../Contexts/DiscoverContexts";
import { CommonError, ToolTipHelper, ZeroResults } from "../Utils/Common";

const NicheMainCategory = () => {
  const { mainCategoryData, setDisplayMode, setSubCategoryID, setIsLoaded } =
    useContext(DiscoverContext);

  function displayMainCategory() {
    let listItems;

    if (mainCategoryData.status_code !== 200) {
      if (mainCategoryData.status_code === 600) {
        listItems = <ZeroResults />;
      } else {
        listItems = <CommonError message={mainCategoryData.status_message} />;
      }
    } else {
      listItems = mainCategoryData.niche_category.map((x) => {
        return (
          <div
            className="main-cats"
            onClick={() => {
              setSubCategoryID(x.id);
              setIsLoaded(false);
              setDisplayMode("sub-category");
            }}
            key={x.id}
          >
            <div className="cat-icon">{getDescription(x.description)}</div>
            <div className="cat-name-wrap">
              <span className="cat-item-count">{x.item_count}</span>
              <span className="cat-name">{x.name.replace(/&amp;/g, "&")}</span>
            </div>
          </div>
        );
      });
    }

    function getDescription(desc) {
      if (desc) {
        return (
          <i
            className={desc.split('"')[1] + " text-slate-900 text-4xl"}
            aria-hidden="true"
          ></i>
        );
      } else {
        return <i className="fa fa-leaf" aria-hidden="true"></i>;
      }
    }

    return (
      <>
        <h1 className="result-header">
          Explore <span>Niche Market Ideas</span> Directory
          <ToolTipHelper title="Niche Directory" pointer="niche_directory" />
        </h1>
        <p className="niches-count">
          <span>{mainCategoryData.niche_total.toLocaleString()}</span> Niche
          Market Ideas At Your Fingertips
        </p>
        <div id="niche-dir">{listItems}</div>
      </>
    );
  }

  // The main return
  return <>{displayMainCategory()}</>;
};

export default NicheMainCategory;
