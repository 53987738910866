import { useState } from "react";
import {
  MdOutlineErrorOutline,
  MdOutlineCheckCircleOutline,
} from "react-icons/md";

import { API_BASE_URL } from "../../Constants/Contants";

import { PageTitle } from "./PageTitle";

function Support() {
  let [content, setContent] = useState("");
  let [submitLabel, setSubmitLabel] = useState("Submit");

  let [status, setStatus] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setStatus("");
    setSubmitLabel("Processing ...");

    const formData = new FormData(e.target);
    const formProps = Object.fromEntries(formData);

    // Check if no empty field
    if (!formProps.email || !formProps.message || !formProps.subject) {
      setStatus("Incomplete form feild!");
    }

    // Submit the form
    let requestOptions = {
      method: "POST",
      redirect: "follow",
    };

    // Convert formdata to querystring
    const formDataArray = [...formData.entries()];

    const qString = formDataArray
      .map((x) => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`)
      .join("&");

    fetch(API_BASE_URL + "/support?" + qString, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setContent(data.result);
        setSubmitLabel(data.result.status_message);
      });
  };

  let sty = "block border border-slate-300 py-5 px-5 text-sm w-full mb-5";

  if (content) {
    if (content.status === 200) {
      return (
        <div id="support" className="w-full max-w-2xl mx-auto">
          <PageTitle title="Support" />
          <div className="max-w-2xl mx-auto">
            <div className="max-w-lg mx-auto">
              <div id="bot-failed"></div>
              <div className="flex items-center bg-orange-600 py-5 px-12 rounded-full text-white text-lg mb-6">
                <div className="basis-20 shrink-0 text-6xl">
                  <MdOutlineCheckCircleOutline />
                </div>
                <div>
                  Thank you for contacting us. We will get in touch shortly!
                </div>
              </div>
              <div className="text-center">
                In the meantime, you can visit our facebook group to collaborate
                with other members.
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div id="support">
          <PageTitle title="Support" />
          <div className="sp-a">
            <div id="bot-failed"></div>
            <div className="warn">
              <div className="w-a">
                <MdOutlineErrorOutline />
              </div>
              <div>
                Uh-oh... Looks like we are having a hard time delivering your
                message.
              </div>
            </div>
            <div className="w-b">
              Do not worry, you can still reach us by emailing us at{" "}
              <a href="mailto:support@ideabot.ai">support@ideabot.ai</a>
            </div>
          </div>
        </div>
      );
    }
  } else {
    return (
      <div id="support" className="w-full max-w-2xl mx-auto">
        <PageTitle title="Support" />
        <div id="bot-form"></div>
        <p className="mb-3 border-t-2 border-slate-400 py-3 px-6">
          If you have any questions please fill out the form below in detail and
          our team will respond promptly.
        </p>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            className={sty}
            required
          />

          <input
            type="text"
            id="subject"
            name="subject"
            placeholder="Subject"
            className={sty}
            required
          />

          <textarea
            id="message"
            name="message"
            placeholder="Your Message"
            className={sty + " h-36"}
            required
          />

          <input
            type="submit"
            value={submitLabel}
            className={
              sty +
              " bg-orange-600 text-white border-orange-600 uppercase cursor-pointer hover:bg-slate-900 max-w-md py-5 mx-auto rounded-full text-xl"
            }
          />
        </form>
        <div>{status}</div>
      </div>
    );
  }
}

export default Support;
