import { SectionHeader } from "../Utils/Common";

import { AiOutlineBulb } from "react-icons/ai";
import { AnalyzeContext } from "../../Contexts/AnalyzeContexts";
import { useContext } from "react";

const Validation = () => {
  let { nicheDrillDown } = useContext(AnalyzeContext);

  console.log(nicheDrillDown);
  return (
    <div id="nvw">
      <h1 className="result-header">Niche Market Validation Worksheet</h1>

      <div id="nvw-left">
        <div className="total">Total</div>
      </div>
      <div id="nvw-entries">
        <div id="nvw-1" className="nvw-section">
          <SectionHeader title="Step 1 : Defining Your Niche" />

          <table className="nvw-s1">
            <tr>
              <td>Market/Industry</td>
              <td>
                <input placeholder="Type here" />
              </td>
            </tr>
            <tr>
              <td>Niche Marketing</td>
              <td>
                <input placeholder="Type here" />
              </td>
            </tr>
            <tr>
              <td>Sub-Niche</td>
              <td>
                <input placeholder="Type here" />
              </td>
            </tr>
            <tr>
              <td>
                Location <span>(Global/Country/City)</span>
              </td>
              <td>
                <input placeholder="Type here" />
              </td>
            </tr>
          </table>
        </div>

        <div id="nvw-2" className="nvw-section">
          <SectionHeader title="Step 2: Describe The Problem(s) Or Passion(s)" />

          <div className="nvw-2-a">
            <p>- [SPECIFIC TARGET AUDIENCE] that want [SPECIFIC RESULT]</p>
            <p>- [SPECIFIC TARGET AUDIENCE] that have a [SPECIFIC PROBLEM]</p>
          </div>

          <div className="nvw-2-b">
            <textarea></textarea>
          </div>
        </div>

        <div id="nvw-3" className="nvw-section">
          <SectionHeader title="Step 3: Keyword Research" />

          <div className="tbl-header  tbl-nvw-3 nvw-3a">
            <div>Keyword/Phrase</div>
            <div>Searches</div>
            <div>CPC</div>
            <div>DIFFICULTY</div>
          </div>

          <div className="tbl-entries">
            <div className="tbl-entry">
              <div>s3a</div>
              <div>s3b</div>
              <div>s3c</div>
              <div>s3d</div>
            </div>
          </div>
        </div>

        <div id="nvw-4" className="nvw-section">
          <SectionHeader title="Step 4: Google Competitor Analysis" />

          <div className="nvw-4a">
            <div>
              <AiOutlineBulb />
            </div>
            <div>
              <ul>
                <li>any site with backlinks less than 100 = 2 points</li>
                <li>YouTube, Facebook, Amazon, etc = 1 point</li>
                <li>direct competition + links greater than 1000 = 1 point</li>
              </ul>
            </div>
          </div>

          <div className="tbl-header">
            <div>Website</div>
            <div>Keywords</div>
            <div>Traffic</div>
            <div>Backlinks</div>
            <div>Authority</div>
            <div>Points</div>
          </div>
          <div className="tbl-entries">
            <div className="tbl-entry">
              <div>s4a</div>
              <div>s4b</div>
              <div>s4c</div>
              <div>s4d</div>
              <div>s5d</div>
              <div>s6d</div>
            </div>
          </div>
        </div>

        <div id="nvw-5" className="nvw-section">
          <SectionHeader title="Step 5: Pay-Per-Click Analysis" />

          <div className="tbl-header">
            <div>Search Engine</div>
            <div># of Advertisers</div>
          </div>
          <div className="tbl-entries">
            <div className="tbl-entry">
              <div>Google Adwords Ads (Primary Keywords)</div>
              <div>s5a</div>
            </div>
            <div className="tbl-entry">
              <div>Bing PPC Ads (Primary Keywords)</div>
              <div>s5b</div>
            </div>
          </div>
        </div>

        <div id="nvw-6" className="nvw-section">
          <SectionHeader title="Step 6: Facebook Advertising Analysis" />

          <div className="tbl-header">
            <div>Facebook</div>
            <div>Total Count</div>
          </div>
          <div className="tbl-entries">
            <div className="tbl-entry">
              <div>Facebook Pages (1000+ likes)</div>
              <div>s6a</div>
            </div>
            <div className="tbl-entry">
              <div>Facebook Groups (Public with 1000+ Members)</div>
              <div>s6b</div>
            </div>
          </div>
        </div>

        <div id="nvw-7" className="nvw-section">
          <SectionHeader title="Step 7: YouTube Marketing Analysis" />

          <div className="tbl-header">
            <div>Youtube</div>
            <div>Total Count</div>
          </div>
          <div className="tbl-entries">
            <div className="tbl-entry">
              <div>YouTube Videos (1000+ views)</div>
              <div>s7a</div>
            </div>
            <div className="tbl-entry">
              <div>YouTube Channels (1000+ subscribers)</div>
              <div>s7b</div>
            </div>
          </div>
        </div>

        <div id="nvw-8" className="nvw-section">
          <SectionHeader title="Step 8: Niche Blog & Media Site Research" />

          <div className="tbl-header">
            <div>Name</div>
            <div>URL</div>
          </div>
          <div className="tbl-entries">
            <div className="tbl-entry">
              <div>s8a</div>
              <div>s8b</div>
            </div>
          </div>
        </div>

        <div id="nvw-9" className="nvw-section">
          <SectionHeader title="Step 9: Product Offer Research" />

          <div className="tbl-header">
            <div>Affiliate Offer</div>
            <div>$/Sale</div>
            <div>URL</div>
          </div>
          <div className="tbl-entries">
            <div className="tbl-entry">
              <div>s9a</div>
              <div>s9b</div>
              <div>s9c</div>
            </div>
          </div>

          <div className="tbl-sep"></div>

          <div className="tbl-header">
            <div>Physical Product</div>
            <div>Price</div>
            <div>URL</div>
          </div>
          <div className="tbl-entries">
            <div className="tbl-entry">
              <div>s9d</div>
              <div>s9e</div>
              <div>s9f</div>
            </div>
          </div>
        </div>

        <div id="nvw-10" className="nvw-section">
          <SectionHeader title="Step 10: Niche Market Validation Scoring" />

          <div className="tbl-header">
            <div>Criteria</div>
            <div>Score</div>
          </div>

          <div className="tbl-entries">
            <div className="tbl-entry">
              <div>
                Primary Keywords <span>2 points each</span>
              </div>
              <div>s10a</div>
            </div>

            <div className="tbl-entry">
              <div>
                Low Direct Competitors <span>total points</span>
              </div>
              <div>s10b</div>
            </div>

            <div className="tbl-entry">
              <div>
                Confirmed Google Advertisers <span>Yes = 3 point</span>
              </div>
              <div>s10c</div>
            </div>

            <div className="tbl-entry">
              <div>
                Confirmed Bing Advertisers <span>Yes = 3 point</span>
              </div>
              <div>s10d</div>
            </div>

            <div className="tbl-entry">
              <div>
                Confirmed Facebook Pages <span>1 points each : Max 5</span>
              </div>
              <div>s10e</div>
            </div>

            <div className="tbl-entry">
              <div>
                Confirmed Public Groups <span>1 points each : Max 5</span>
              </div>
              <div>s10f</div>
            </div>

            <div className="tbl-entry">
              <div>
                Confirmed Niche YouTube Videos{" "}
                <span>1 points each : Max 5</span>
              </div>
              <div>s10g</div>
            </div>

            <div className="tbl-entry">
              <div>
                Confirmed Niche YouTube Channels
                <span>1 points each : Max 5</span>
              </div>
              <div>s10f</div>
            </div>

            <div className="tbl-entry">
              <div>
                Niche Blogs & Media Sites
                <span>1 points each : Max 5</span>
              </div>
              <div>s10g</div>
            </div>

            <div className="tbl-entry">
              <div>
                Affiliate offers confirmed
                <span>1 points each : Max 5</span>
              </div>
              <div>s10h</div>
            </div>

            <div className="tbl-entry">
              <div>
                Physical products confirmed
                <span>1 points each : Max 5</span>
              </div>
              <div>s10i</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Validation;
