import { useContext, useState } from "react";

import { AppContext } from "../../Contexts/AppContexts";
import { AnalyzeContext } from "../../Contexts/AnalyzeContexts";

import { MdAutoGraph, MdOutlineDataUsage } from "react-icons/md";
import { BsYoutube, BsTwitter, BsReddit } from "react-icons/bs";
import { FaQuora, FaAmazon, FaGoogle, FaEbay, FaFacebookSquare, FaAppStoreIos } from "react-icons/fa";
import { SiAliexpress, SiGodaddy } from "react-icons/si";

import { BasicPrompt, keyGen, SectionHeader } from "../Utils/Common";
import { KeywordIdeas } from "./KeywordIdeas";
import { FullReport } from "./FullReport";
import { KeywordHistoricalData } from "./KeywordHistoricalData";

const Analyze = () => {
  let [nicheDrillDown, setNicheDrillDown] = useState(false);

  const { queryString, doSearch } = useContext(AppContext);

  function navClick(e) {
    e.preventDefault();

    const target = e.target.getAttribute("href");
    const location = document.querySelector(target).offsetTop;

    window.scrollTo({
      left: 0,
      top: location - 20,
    });

    document.querySelector("#analyze-nav .active").classList.remove("active");
    e.target.classList.add("active");
  }

  if (queryString && doSearch) {
    return (
      <div id="analyze-wrap">
        <div id="analyze" className="scroll-smooth">
          <h1 className="result-header">
            Online Market Analysis for <span className="text-orange-600">{queryString}</span>
          </h1>

          <AnalyzeContext.Provider value={{ nicheDrillDown, setNicheDrillDown }}>
            <div id="analyze-1" className="mb-12">
              <div id="keyword-data">
                <SectionHeader title="Seo Analysis" ToolTipTitle="SEO Analysis" ToolTipPointer="seo_analysis" />
                <KeywordHistoricalData />
              </div>
              <div id="keyword-ideas">
                <KeywordIdeas />
              </div>
            </div>

            {/* <Validation /> */}

            <FullReport q={queryString} key={keyGen()} />
          </AnalyzeContext.Provider>

          <div id="analyze-nav" style={{ display: "none" }}>
            <a className="active hover:scroll-auto" href="#root" onClick={navClick}>
              <MdAutoGraph />
            </a>
            <a href="#serp" onClick={navClick}>
              <MdOutlineDataUsage />
            </a>
            <a href="#youtube" onClick={navClick}>
              <BsYoutube />
            </a>
            <a href="#quora" onClick={navClick}>
              <FaQuora />
            </a>
            {/* <a href="#twitter" onClick={navClick}>
              <BsTwitter />
            </a> */}
            <a href="#reddit" onClick={navClick}>
              <BsReddit />
            </a>
            <a href="#fb" onClick={navClick}>
              <FaFacebookSquare />
            </a>
            <a href="#itunes" onClick={navClick}>
              <FaAppStoreIos />
            </a>
            <a href="#amazon" onClick={navClick}>
              <FaAmazon />
            </a>
            <a href="#google" onClick={navClick}>
              <FaGoogle />
            </a>
            <a href="#ebay" onClick={navClick}>
              <FaEbay />
            </a>
            {/* <a href="#aliexpress" onClick={navClick}>
              <SiAliexpress />
            </a> */}

            <a href="#godaddy" onClick={navClick}>
              <SiGodaddy />
            </a>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div id="analyze">
        <BasicPrompt />
      </div>
    );
  }
};

export default Analyze;
