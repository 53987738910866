import { useState, useEffect, useCallback, useContext } from "react";

import { BasicLoader, SectionHeader, ZeroResults, fetchHeaders } from "../../Utils/Common";
import { MdOutlineNorthEast } from "react-icons/md";

export const ItunesApps = ({ q }) => {
  let [isLoaded, setIsLoaded] = useState(false);
  let [content, setContent] = useState(false);

  const getItunesApps = useCallback(() => {
    fetch("https://itunes.apple.com/search?media=software&limit=5&term=" + q, fetchHeaders())
      .then((response) => response.json())
      .then((data) => {
        setContent(data);
        setIsLoaded(true);
        console.log("iTunes Podcast Fetched");
      });
  }, [q]);

  useEffect(() => {
    getItunesApps();
  }, [q, getItunesApps]);

  function App({ data }) {
    return (
      <div className="it-entry">
        <div className="it-a">
          <img src={data.artworkUrl100} alt={data.trackCensoredName} />
        </div>
        <div className="it-b">
          <h3 className="it-b-1">
            <a href={data.trackViewUrl} target="_blank" rel="noreferrer">
              <span className="mr-2">{data.trackCensoredName}</span>
              <MdOutlineNorthEast className="inline text-orange-600  mx-1" />
            </a>
          </h3>
          <div className="it-b-2">
            {"by "}
            <span className="">{data.sellerName.substring(0, 80)}</span>
          </div>
          <div className="it-b-3">{data.description.substring(0, 150)} ...</div>
        </div>
      </div>
    );
  }

  function displayItunesApps() {
    let listItems;

    // Check if we got results
    if (content.resultCount === 0) {
      listItems = <ZeroResults />;
    } else {
      listItems = content.results.map((x) => {
        return <App key={x.trackId} data={x} />;
      });
    }

    return (
      <>
        <SectionHeader title="iTunes Apps" ToolTipTitle="iTunes Apps" ToolTipPointer="itunes_apps" />

        <div className="it-entries">{listItems}</div>
      </>
    );
  }

  if (isLoaded) {
    return <>{displayItunesApps()}</>;
  } else {
    return (
      <>
        <SectionHeader title="iTunes Apps" ToolTipTitle="iTunes Apps" ToolTipPointer="itunes_apps" />
        <BasicLoader />
      </>
    );
  }
};
