import React from "react";

function Footer() {
  return (
    <div
      id="footer"
      className="py-4 mt-6 border-slate-300 border-t text-center text-xs text-slate-500">
      <p className="copyr">
        Copyright {new Date().getFullYear()} IdeaBot. All Rights Reserved
      </p>
      <ul className="footer-nav">
        <li>
          <a
            href="https://ideabot.ai/terms-and-conditions/"
            target="_blank"
            rel="noreferrer">
            Terms and Conditions
          </a>
        </li>
        <li>
          <a
            href="https://ideabot.ai/affiliate-disclaimer/"
            target="_blank"
            rel="noreferrer">
            Affiliate Disclaimer
          </a>
        </li>
        <li>
          <a
            href="https://ideabot.ai/privacy-policy-2/"
            target="_blank"
            rel="noreferrer">
            Privacy Policy
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Footer;
