import { useState, useEffect, useCallback, useContext } from "react";

import { AppContext } from "../../Contexts/AppContexts";
import { MiniLoader, fetchHeaders, difficultyColor } from "../Utils/Common";

import { API_BASE_URL } from "../../Constants/Contants";
import { KeywordsContext } from "../../Contexts/KeywordsContext";

export const KeywordDifficulty = ({ keyword, updateList }) => {
  const { country } = useContext(AppContext);
  const { content, setContent } = useContext(KeywordsContext);

  let [isLoaded, setIsLoaded] = useState(false);
  let [kwdata, setKwdata] = useState(false);

  const getKeywordDifficultyKw = useCallback(() => {
    fetch(
      API_BASE_URL +
        "/keyword-difficulty?q=" +
        keyword +
        "&location_code=" +
        country.location_code,
      fetchHeaders()
    )
      .then((response) => response.json())
      .then((data) => {
        setKwdata(data);
        setIsLoaded(true);

        // Let's check if we need to update the list
        if (updateList && data.result.difficulty !== undefined) {
          generateNewList(data.result.difficulty);
        }

        console.log("Keyword Difficulty Fetched!");
      });
  }, [keyword]);

  useEffect(() => {
    setIsLoaded(false);
    getKeywordDifficultyKw();
  }, [keyword]);

  function generateNewList(val) {
    // Let's copy the current content and set it to variable that we can edit
    let newEntry = content;

    // Test what type of content
    if (content.result.response[0].keyword !== undefined) {
      content.result.response.map((item, index) => {
        if (item.keyword === keyword) {
          newEntry.result.response[
            index
          ].keyword_properties.keyword_difficulty = val;

          setContent(newEntry);
        }
      });
    } else {
      // Find this entry the has missing keyword
      content.result.response.map((item, index) => {
        console.log(item);

        if (item.keyword_data.keyword === keyword) {
          newEntry.result.response[
            index
          ].keyword_data.keyword_properties.keyword_difficulty = val;

          setContent(newEntry);
        }
      });
    }
  }
  return isLoaded ? (
    <div className={difficultyColor(kwdata.result.difficulty) + " difficulty"}>
      {kwdata.result.difficulty}
    </div>
  ) : (
    <MiniLoader />
  );
};
