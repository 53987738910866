import { useState, useEffect, useCallback, useContext } from "react";

import {
  ResponsiveContainer,
  AreaChart,
  XAxis,
  YAxis,
  Area,
  Tooltip,
  CartesianGrid,
  ReferenceLine,
} from "recharts";

import { AppContext } from "../../Contexts/AppContexts";
import { difficultyColor, ToolTipHelper } from "../Utils/Common";

import { API_BASE_URL } from "../../Constants/Contants";
import {
  BasicLoader,
  CommonError,
  fetchHeaders,
  ZeroResults,
  SectionHeader,
} from "../Utils/Common";

import { MONTHS } from "../../Constants/Contants";

export const KeywordHistoricalData = () => {
  const { queryString, country } = useContext(AppContext);

  let [isLoaded, setIsLoaded] = useState(false);
  let [content, setContent] = useState(false);

  const getKeywordData = useCallback(() => {
    fetch(
      API_BASE_URL +
        "/analyze/historical-search-volume?q=" +
        queryString +
        "&location_code=" +
        country.location_code,
      fetchHeaders()
    )
      .then((response) => response.json())
      .then((data) => {
        setContent(data);
        setIsLoaded(true);
        console.log("Keyword Data Fetched!");
      });
  }, [queryString]);

  useEffect(() => {
    getKeywordData();
    setIsLoaded(false);
  }, [queryString, getKeywordData]);

  function displayGraph() {
    function CustomTooltip({ active, payload, label }) {
      if (active) {
        return (
          <div className="bg-white p-2 text-xs">
            <span className="font-bold">{label} - </span>
            {Number(payload[0].value).toLocaleString(2)}{" "}
          </div>
        );
      }
      return null;
    }

    const averageValue = () => {
      let val = "";
      content.result.response[0].keyword_info.monthly_searches.map((item) => {
        val = Number(val + item.search_volume);
        return null;
      });

      return parseInt(
        Number(
          val / content.result.response[0].keyword_info.monthly_searches.length
        )
      );
    };

    if (content.result) {
      if (content.result.status_code !== 200) {
        if (content.result.status_code === 600) {
          return (
            <>
              <SectionHeader title="Seo Analysis" />
              <ZeroResults />
            </>
          );
        } else {
          return (
            <>
              <SectionHeader title="Seo Analysis" />
              <CommonError message={content.result.status_message} />
            </>
          );
        }
      } else {
        // Let's create chart data
        let cd;
        cd = content.result.response[0].keyword_info.monthly_searches.map(
          (item) => {
            return {
              search_volume: item.search_volume,
              date: MONTHS[item.month - 1] + " " + item.year,
            };
          }
        );

        let dValue =
          content.result.response[0].keyword_properties.keyword_difficulty;

        return (
          <>
            <div className="kd">
              <div className="kd-1">
                <h4 className="uppercase font-bold text-slate-400">
                  Search Volume
                  <ToolTipHelper
                    title="Search Volume"
                    pointer="organic_search_volume"
                  />
                </h4>

                <div className="kd-1-a">
                  {Number(
                    content.result.response[0].keyword_info.search_volume
                  ).toLocaleString()}
                </div>
              </div>
              <div className="kd-2">
                <h4 className="uppercase font-bold text-slate-400">
                  Cost Per Click
                  <ToolTipHelper
                    title="Cost Per Click"
                    pointer="cost_per_click"
                  />
                </h4>
                <div className="kd-2-a">
                  {Number(content.result.response[0].keyword_info.cpc).toFixed(
                    2
                  )}
                </div>
              </div>

              <div className="kd-2">
                <h4 className="uppercase font-bold text-slate-400">
                  Keyword Difficulty
                  <ToolTipHelper
                    title="Keyword Difficulty"
                    pointer="keyword_difficulty"
                  />
                </h4>

                <div className="kd-3-a">
                  <div className={difficultyColor(dValue) + " difficulty"}>
                    {dValue}
                  </div>
                </div>
              </div>
            </div>
            <div className="h-64">
              <h4 className="uppercase font-bold text-slate-400 text-center mb-2">
                HISTORICAL SEARCH TREND
                <ToolTipHelper
                  title="Search Trend"
                  pointer="12_month_search_trend"
                />
              </h4>
              <ResponsiveContainer>
                <AreaChart data={cd.slice(0).reverse()}>
                  <defs>
                    <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="0%" stopColor="#EA580C" stopOpacity={0.4} />
                      <stop
                        offset="75%"
                        stopColor="#EA580C"
                        stopOpacity={0.05}
                      />
                    </linearGradient>
                  </defs>

                  <Area
                    dataKey="search_volume"
                    stroke="#EA580C"
                    fill="url(#color)"
                    type="monotone"
                    strokeWidth={1}
                    dot={{
                      stroke: "#f97316",
                      strokeWidth: 1,
                      r: 3,
                      fillOpacity: 1,
                      fill: "white",
                    }}
                  />
                  <CartesianGrid
                    strokeDasharray="0"
                    stroke={"#e9e9e9"}
                    strokeWidth={1}
                    vertical={false}
                    horizontal={false}
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <XAxis
                    dataKey="date"
                    tickLine={false}
                    axisLine={false}
                    tick={{ fontSize: 10 }}
                    // tickFormatter={(month) => MONTHS[month - 1]}
                  />
                  <YAxis
                    dataKey="search_volume"
                    tickLine={false}
                    axisLine={false}
                    type={"number"}
                    tickFormatter={(number) => Number(number).toLocaleString(2)}
                    tick={{ fontSize: 10 }}
                  />
                  <ReferenceLine
                    y={averageValue()}
                    stroke="green"
                    strokeDasharray="3 3"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </>
        );
      }
    } else {
      return <CommonError />;
    }
  }

  return isLoaded ? (
    <>{displayGraph()}</>
  ) : (
    <>
      <BasicLoader />
    </>
  );
};
