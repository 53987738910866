import logo from "../../images/ib-h.svg";
import SubNavigation from "../Pages/SubNavigation";

import { MdMenu } from "react-icons/md";
import AccountSideMenu from "./Account/AccountSideMenu";
import { APP_VERSION } from "../../Constants/Contants";

function Header() {
  function showMenu() {
    let menuElem = document.getElementById("pages-nav");

    menuElem.classList.toggle("show-menu");
  }

  return (
    <>
      <AccountSideMenu loaded={false} />
      <div
        id="header"
        className="flex items-center px-8 py-2 bg-slate-50 shadow-sm relative z-50 "
      >
        <div className="logo-wrap">
          <img
            src={logo}
            className="ideabot-logo"
            alt="logo"
            onClick={() => window.location.replace("/")}
          />
        </div>

        <div className="grow text-right">
          <SubNavigation />
          <MdMenu className="nav-icon" onClick={showMenu} />
        </div>
      </div>
      <div
        id="progress-holder"
        className="block h-0.5 mb-8 bg-slate-300 overflow-hidden"
      >
        <div
          id="progress-bar"
          className=" bg-orange-400 h-0.5"
          style={{ width: 0 }}
        ></div>
      </div>
    </>
  );
}

export default Header;
