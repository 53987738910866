import { useState, useEffect, useCallback, useContext } from "react";

import { BasicLoader, SectionHeader } from "../../Utils/Common";
import { MdOutlineNorthEast } from "react-icons/md";

export const SubReddit = ({ q }) => {
  let [isLoaded, setIsLoaded] = useState(false);
  let [content, setContent] = useState(false);

  const getSubReddits = useCallback(() => {
    fetch("https://www.reddit.com/search.json?type=sr&limit=5&q=" + q)
      .then((response) => response.json())
      .then((data) => {
        setContent(data);
        setIsLoaded(true);
        console.log("Sub-Reddit Fetched");
      });
  }, [q]);

  useEffect(() => {
    getSubReddits();
  }, [q, getSubReddits]);

  function displaySubReddit() {
    let listItems = content.data.children.map((x, index) => {
      return <Subs x={x} key={index} />;
    });

    return (
      <>
        <SectionHeader title="Popular Sub-Reddits" ToolTipTitle="Popular Sub-Reddits" ToolTipPointer="subreddit" />
        <div className="red-entries">{listItems}</div>
      </>
    );
  }

  if (isLoaded) {
    return <>{displaySubReddit()}</>;
  } else {
    return (
      <>
        <SectionHeader title="Popular Sub-Reddits" ToolTipTitle="Popular Sub-Reddits" ToolTipPointer="subreddit" />
        <BasicLoader />
      </>
    );
  }
};

function Subs(data) {
  let x = data.x;
  let desc = x.data.public_description;

  if (!desc) {
    desc = x.data.description;
  }
  return (
    <div className="red-entry">
      <h3 className="red-title">
        <a href={`https://www.reddit.com/${x.data.url}`} target="_blank" rel="noreferrer">
          <span className="red-a">{x.data.display_name}</span>
          <span className="red-b">{x.data.title}</span>
          <MdOutlineNorthEast className="ext-link" />
        </a>
      </h3>
      <div className="red-stat mb-2">
        <span className="sub-dir">{x.data.display_name_prefixed}</span>
        <span className="sub-count">{Number(x.data.subscribers).toLocaleString()}</span> subscribers, a community for{" "}
        <span className="sub-member">{Number(x.data.created_utc).toLocaleString()}</span>
      </div>
      <div className="red-desc">{desc.substring(0, 150)} ...</div>
    </div>
  );
}
