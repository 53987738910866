import { useEffect } from "react";

function Logout() {
  useEffect(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("countryList");
    localStorage.removeItem("tooltip");
    localStorage.removeItem("credits_info");
    localStorage.removeItem("user_info");

    setInterval(() => {
      window.location.replace("/login");
    }, 500);
  });

  return (
    <div id="logout">
      <div className="logout-wrap">
        <div className="bot-wrap"></div>
        <div className="bot-message">
          We are cleaning up the mess before you go away ...
        </div>
        <div className="spinner">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
      </div>
    </div>
  );
}

export default Logout;
