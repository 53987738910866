import { useState, useContext } from "react";
import { MdBookmark } from "react-icons/md";

import { API_BASE_URL } from "../../Constants/Contants";
import { AppContext } from "../../Contexts/AppContexts";
import { fetchHeaders } from "./Common";

const SaveToFavorites = ({ keyword }) => {
  let [message, setMessage] = useState("Save To Favorites");
  let [btnClass, setBtnClass] = useState("");
  const { setAccountInfo } = useContext(AppContext);

  let userInfo = JSON.parse(localStorage.getItem("user_info"));
  let savedNiches = userInfo.details.saved_niche;

  function saveKeyword(keyword) {
    setMessage("Saving to favorites ...");
    setBtnClass("btn-disabled");

    // Let's save to database
    fetch(
      API_BASE_URL + "/favorites/?q=" + keyword.toLowerCase(),
      fetchHeaders()
    )
      .then((response) => response.json())
      .then((data) => {
        // Save to machine
        localStorage.setItem("user_info", JSON.stringify(data.result));

        setAccountInfo(data.result);
        setMessage("Save To Favorites");
      });
  }

  if (!savedNiches) {
    return <></>;
  }
  if (!keyword) {
    return <></>;
  }
  if (savedNiches) {
    if (savedNiches.includes(keyword.toLowerCase())) {
      return <></>;
    }
  }
  return (
    <div
      id="favorites"
      className={btnClass}
      onClick={() => saveKeyword(keyword)}
    >
      <MdBookmark className=" inline" />
      <span id="fav-text">{message}</span>
    </div>
  );
};

export default SaveToFavorites;
