import { useContext } from "react";
import { AppContext } from "../../Contexts/AppContexts";

import TextInput from "./TextInput";
import CountrySelector from "./CountrySelector";
import ExpandModeSelector from "./ExpandModeSelector";
import SubmitButton from "./SubmitButton";
import ModeSelector from "../ModeSelector/ModeSelector";

const SearchForm = () => {
  const { mode } = useContext(AppContext);
  return (
    <div className="sb">
      <ModeSelector />
      <form action="" className={mode + " sf"}>
        <TextInput />

        {mode === "analyze" ? <CountrySelector /> : ""}

        {mode === "expand" ? (
          <>
            <ExpandModeSelector />
            <CountrySelector />
          </>
        ) : (
          ""
        )}

        <SubmitButton />
      </form>
    </div>
  );
};

export default SearchForm;
