import { useState, useEffect, useCallback, useContext } from "react";
import { AnalyzeContext } from "../../Contexts/AnalyzeContexts";
import { AppContext } from "../../Contexts/AppContexts";

import {
  BasicLoader,
  SectionHeader,
  fetchHeaders,
  ZeroResults,
  CommonError,
} from "../Utils/Common";
import { API_BASE_URL } from "../../Constants/Contants";
import { MdOutlineNorthEast } from "react-icons/md";

export const Quora = () => {
  let [isLoaded, setIsLoaded] = useState(false);
  let [content, setContent] = useState(false);
  let [retry, setRetry] = useState(0);

  const { queryString } = useContext(AppContext);

  const getQuestions = useCallback(() => {
    fetch(API_BASE_URL + "/quora?referer=ask&q=" + queryString, fetchHeaders())
      .then((response) => response.json())
      .then((data) => {
        setContent(data);

        // Zero results, let's retry
        if (data.result.status_code === 600) {
          setRetry(++retry);
          // Zero results ... let's retry
          if (retry < 3) {
            getQuestions();
          }
        } else {
          setIsLoaded(true);
        }

        console.log("data for " + queryString + " was fetched");
      });
  }, [queryString]);

  useEffect(() => {
    getQuestions();
    setIsLoaded(false);
  }, [queryString, getQuestions]);

  function displayQuestions() {
    let listItems;

    if (content.result) {
      if (content.result.status_code !== 200) {
        if (content.result.status_code === 600) {
          listItems = <ZeroResults />;
        } else {
          listItems = <CommonError message={content.result.status_message} />;
        }
      } else {
        listItems = content.result.response.map((x, index) => {
          return (
            <Questions
              key={index}
              index={index + 1}
              question={x.question}
              link={x.link}
            />
          );
        });
      }
    } else {
      listItems = <CommonError />;
    }

    return (
      <>
        <div id="quora">
          <SectionHeader
            title="QUORA QUESTIONS"
            ToolTipTitle="Quora Questions"
            ToolTipPointer="quora_questions"
          />
          {listItems}
          <div className="more-btn-wrap">
            <a
              href={`https://www.quora.com/search?q=` + encodeURI(queryString)}
              target="_blank"
              rel="noreferrer"
              className="more-btn"
            >
              View More Questions
            </a>
          </div>
        </div>
      </>
    );
  }

  return isLoaded ? (
    <>{displayQuestions()}</>
  ) : (
    <>
      <SectionHeader
        title="QUORA QUESTIONS"
        ToolTipTitle="Quora Questions"
        ToolTipPointer="quora_questions"
      />
      <BasicLoader />
    </>
  );
};

const Questions = ({ index, question, link }) => {
  return (
    <div className="q-entry">
      <div className="q-a">{index}</div>
      <div className="q-b">
        <a href={link} target="_blank" rel="noreferrer">
          {question}
          <MdOutlineNorthEast className="ext-link" />
        </a>
      </div>
    </div>
  );
};
