import { useContext } from "react";

import { API_BASE_URL } from "../../Constants/Contants";
import { AppContext } from "../../Contexts/AppContexts";
import { Buffer } from "buffer";

const DownloadButton = ({ wrapper }) => {
  const { mode, queryString, accountInfo } = useContext(AppContext);

  function downloadReport() {
    // Update Progress
    document.getElementById("download-btn").innerHTML =
      '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>';

    let myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    let fdata = document.getElementById(wrapper).innerHTML;

    const formdata = new FormData();
    formdata.append("html", fdata);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: formdata,
    };

    fetch(API_BASE_URL + "/pdf", requestOptions)
      .then((res) => res.arrayBuffer())
      .then((data) => {
        var base64Str = Buffer.from(data).toString("base64");

        var binaryString = window.atob(base64Str);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
          var ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }
        var arrBuffer = bytes;

        var newBlob = new Blob([arrBuffer], { type: "application/pdf" });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }

        data = window.URL.createObjectURL(newBlob);
        let fileName = mode + "-" + queryString + ".pdf";

        // var link = document.createElement("a");
        // document.body.appendChild(link);
        // link.href = data;
        // link.download = "Faktura.pdf";
        // link.click();
        // window.URL.revokeObjectURL(data);
        // link.remove();

        // let fileURL = window.URL.createObjectURL(newBlob);
        // window.open(fileURL);

        // Download the file
        let a = document.createElement("a");
        a.setAttribute("download", fileName);
        a.setAttribute("href", data);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        document.getElementById("download-btn").innerHTML = "Download Report";
      });
  }

  if (accountInfo.details.access_level === "pro") {
    return (
      <div
        id="download-btn"
        onClick={() => {
          downloadReport();
        }}>
        Download Report
      </div>
    );
  } else {
    return (
      <div className="download-btn-wrapper">
        <div id="download-btn" className="disabled">
          Download Report
        </div>
        <div className="download-help">
          Downloading report is not available to basic members. <br />
          <strong>Please UPGRADE to PRO account.</strong>
        </div>
      </div>
    );
  }
};

export default DownloadButton;
